<template>
  <div class="orders-table table-responsive m-0">
    <table class="table-bordered border-primary text-center w-100" height="100">
      <thead>
        <tr>
          <th class="text-center">
            Назва закупівлі
          </th>
          <th class="text-center">
            Завантажити
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in orders" :key="item.id">
          <td :title="item.filename" class="p-3"><img class="mx-1" :src="docx" width="50" /> {{item.filename.length > 20 ? item.filename.substr(0, 17) + '...' : item.filename}}</td>
          <td title="Завантажити закупівлю" class="p-3"><a class="text-decoration-none" :href="item.href" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 24 24">
                <path d="M19.355,10.036C18.674,6.595,15.641,4,12,4C9.108,4,6.603,5.639,5.352,8.036C2.343,8.36,0,10.906,0,14c0,3.314,2.686,6,6,6 h13c2.761,0,5-2.239,5-5C24,12.36,21.948,10.221,19.355,10.036z M12,18l-5-5h3V9h4v4h3L12,18z"></path>
            </svg>
          </a></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import toastr from 'toastr';
import 'toastr/toastr.scss';

export default {
    name: "PuchaseAnnouncement",
    props: {
      orders: Object,
      docx: Object
    },
}
</script>