<template>
  <div class="d-flex align-items-center justify-content-center w-100" style='height: 100vh;'>
    <div class="spinner-border" style="width: 12rem; height: 12rem;" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
    name: "LoaderView",
}
</script>