<template>
  <div class="PersonalAccountsPage">
    <div class="row mb-2">
      <div class="col-md-6 payment-card" v-for='payment in paymentsList' :key='payment.id' :accountId="payment.account">
        <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
          <div class="col p-4 d-flex flex-column position-static">
            <strong class="d-inline-block mb-2 text-primary-emphasis">№{{payment.id}}</strong>
            <h4 class="mb-0">{{payment.service}}</h4>
            <div class="text-body-secondary">Особовий рахунок: {{payment.account}}</div>
            <div class="mb-1 text-body-secondary">Адреса: {{payment.address}}</div>
            <a class="icon-link gap-1 mt-1" @click.prevent='lookAccount(payment, this.url)'>
              Переглянути рахунок
            </a>
            <a class="icon-link gap-1 mt-1" @click.prevent='deleteAccount(payment.id)'>
              Видалити рахунок
            </a>
          </div>
        </div>
      </div>
    </div>

    <nav v-if='payments_pagination === true' class="d-flex pagination-nav align-items-center justify-content-center" aria-label="Page navigation example">
      <ul class="pagination">
        <li class="page-item">
          <a class="page-link" href="#" aria-label="Next" @click.prevent='next'>
            <span aria-hidden="true">
              <svg width="20px" height="20px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <polygon points="50,80 80,20 20,20" style="fill:black;stroke:black;stroke-width:3;"/>
              </svg>
            </span>
          </a>
        </li>
        <li class="page-item">
          <a class="page-link" href="#" aria-label="Prev" @click.prevent='prev'>
            <span aria-hidden="true">
              <svg width="20px" height="20px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <polygon points="50,20 80,80 20,80" style="fill:black;stroke:black;stroke-width:3;"/>
              </svg>
            </span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import delete_payment from '../mixins/api.js';
import invoiceFunc from '../mixins/api.js';
import toastr from 'toastr';
import 'toastr/toastr.scss';
import xlsx from '@/assets/xlsx.png'

export default {
  name: 'PersonalAccountsPage',
  mixins: [delete_payment, invoiceFunc],
  props: {
    paymentsList: Array,
    unBlockAllButtons: Function,
    blockAllButtons: Function
  },
  data() {
    return {
      url: this.$store.getters.getUrl,
      payments_pagination: false,
      payments_list: null,
      page: 0,
      cards: null,
      xlsx: xlsx
    }
  },
  mounted() {
   this.updatedData();
   toastr.options = {
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-top-center',
      timeOut: 1300,
      toastClass: 'custom-toast',
   }
  },
  updated() {
    this.updatedData();
  },
  methods: {
    updatedData() {
      this.cards = [...document.querySelectorAll('.payment-card')];
      this.deleteSession();
      this.pagination();
    },

    deleteSession() {
      sessionStorage.removeItem('page');
      sessionStorage.removeItem('page-single');
    },

    pagination() {
      if(this.paymentsList.length > 2 && this.cards.length > 2) {
        this.payments_pagination = true;

        this.cards.forEach((card, index) => {
          if (index >= 2) {
            card.classList.add('d-none');
          }
        })
      } else {
        this.payments_pagination = false;
      }

      window.scrollTo(0, document.body.scrollHeight);
    },

    prev() {
      const className = 'd-none';
      let hiddenCount = 0;

      if (sessionStorage.getItem('page') !== null && parseInt(sessionStorage.getItem('page')) > 0) {
        for (let i = this.cards.length - 1; i >= 0; i--) {
          const card = this.cards[i];
          if (hiddenCount < 2) {
            if (sessionStorage.getItem('page-single') !== null) {
              if (!card.classList.contains(className) && hiddenCount < 1) {
                card.classList.add(className);
                hiddenCount++;
              }
            } else {
              if (!card.classList.contains(className) && hiddenCount < 2) {
                card.classList.add(className);
                hiddenCount++;
              }
            }
          }
        }

        sessionStorage.removeItem('page-single');
      }

      if (sessionStorage.getItem('page')) {
        const value = parseInt(sessionStorage.getItem('page')) - 1 < 0 ? 0 : parseInt(sessionStorage.getItem('page')) - 1;
        sessionStorage.setItem('page', value);
      }
    },

    next() {
      const className = 'd-none';
      var hiddenCount = 0;
      var visibility = false;

      this.cards.forEach((card) => {
        if (card.classList.contains(className) && hiddenCount < 2) {
          visibility = true;
          card.classList.remove(className);
          hiddenCount++;
        }
      });

      if(hiddenCount === 1) {
        sessionStorage.setItem('page-single', true);
      }

      if(visibility === true) {
        if(sessionStorage.getItem('page')) {
           sessionStorage.setItem('page', parseInt(sessionStorage.getItem('page')) + 1);
        } else {
          sessionStorage.setItem('page', 1);
        }
      }

      window.scrollTo(0, document.body.scrollHeight);
    },

    async deleteAccount(id) {
      document.querySelector('.cs-loader').classList.remove('d-none'); 
      this.blockAllButtons();

      try {
        // const mainBlock = document.querySelector(`.PersonalAccountsPage > .row`);
        // const block = document.querySelector(`div[accountId='${account}']`)
        const request = await this.delete_payment(id);
        if(request.status === 200) {
          var storage = sessionStorage.getItem('accounts_page');
          if(storage !== null) {
            sessionStorage.removeItem('accounts_page');
          }
          
          toastr.options.onHidden = () => {window.location.reload()};
          toastr.success('', request.message)
        } else {
          toastr.options.onHidden = () => {};

          toastr.error('', request.message)

          document.querySelector('.cs-loader').classList.add('d-none');
          this.unBlockAllButtons();
        }
      } catch(error) {
        toastr.options.onHidden = () => {};

        toastr.error('', error)
        console.error(error);

        document.querySelector('.cs-loader').classList.add('d-none');
        this.unBlockAllButtons();
      }
    },

    async lookAccount(payment, url) {
      const day = new Date().getDate();
      const month = new Date().getMonth() + 1;
      const year = new Date().getFullYear();

      const today = (day < 10 ? '0' + day : day) + '.' + (month < 10 ? '0' + month : month) + '.' + year;

      document.querySelector('.carousel-inner').innerHTML = '';
      document.querySelector('.modal').classList.add('d-block');
      document.querySelector('.modal-title').innerText = `№${payment.id}`;
      document.querySelector('.modal-body > p').innerHTML = `
        <div class="card-body">
          <ul class="list-unstyled mt-2 mb-4">
            <li><b>Номер особового рахунку:</b> ${payment.account}</li>
            <li><b>Адреса:</b> ${payment.address}</li>
            <li><b>Послуга:</b> ${payment.service}</li>
            <li><b>Сума боргу станом на ${today}:</b> ${payment.debt > 0 ? "+" + payment.debt : payment.debt} грн.</li>
          </ul>
  
          <a type="button" class="w-100 btn btn-sm btn-outline-danger shadow shadow-danger" href='${url}/billings/save?account=${payment.account}&city=${payment.city}' target='_blank'>
              <img src='${this.xlsx}' width='30' height='30' alt='xlsx.png' />
              <span class="mx-2">Завантажити борги по рахунку <span class="text-decoration-underline">${payment.account}</span></span>
          </a>
        </div>
      `;

      /* if(payment.debt < 0) {
        const list = document.querySelector('.list-unstyled')

        const button = document.createElement('button');
        button.classList.add("mb-2", "w-100", "btn", "btn-sm", "btn-outline-secondary", "shadow", "shadow-danger", "create_invoice", "p-2");
        button.innerHTML = `<span class="mx-2">Сплатити зараз <span class="text-decoration-underline">${payment.debt} грн.</span></span>`
        list.after(button);

        button.onclick = async () => { 
          this.blockAllButtons();
          document.querySelector('.cs-loader').classList.remove('d-none');

          try {
            var userFullInfo = sessionStorage.getItem('userFullInfo');
            userFullInfo = JSON.parse(userFullInfo);

            const name = userFullInfo.f;
            const email = userFullInfo.e;
            const phone = userFullInfo.phone;
            const card = userFullInfo.card;
            var card_exp_month = userFullInfo.card_exp_month;
            const card_exp_year = userFullInfo.card_exp_year;
            const card_cvv = userFullInfo.card_cvv;
            const cards_info = [card, card_exp_month, card_exp_year, card_cvv];

            if(cards_info.includes(null) || cards_info.includes(undefined)) {
              toastr.error('Будь-ласка, заповніть реквізити банківського рахунку перед оплатою послуг на сторінці профілю!')
            } else {
              card_exp_month < 10 ? card_exp_month = '0' + card_exp_month : false;
              const request = await this.invoiceFunc(card, card_exp_month, card_exp_year.substr(2), card_cvv, name, email, phone, payment.account, payment.city, payment.service, (payment.debt * -1));
              const message = request.message;
              if(request.status === 200) {
                sessionStorage.removeItem('accounts_page');
                toastr.options.onHidden = () => {window.location.reload()};
                toastr.success(message)
              } else {
                toastr.error(message)
              }
            }
          } catch (error) {
            console.error(error);
            toastr.error("Упс! Перевірте з'єднання з інтернетом або спробуйте ще раз трішки пізніше!")
          }

          document.querySelector('.cs-loader').classList.add('d-none');
          return this.unBlockAllButtons();
        }
      } */
    }
  }
}

</script>

<style>

</style>