import axios from 'axios';
import KJUR from 'jsrsasign';

export default {
	data() {
		return {
			url: this.$store.getters.getUrl,
			aws_sns_api_key: this.$store.getters.getAwsSnsApiKey,
			aws_sns_api_url: this.$store.getters.getAwsSnsApiUrl,
			secret_key: this.$store.getters.getSecretKey,
		}
	},
	methods: {
		generate_token(payload) {
			const header = {alg: 'HS256', typ: 'JWT'};
			const token = KJUR.jws.JWS.sign("HS256", header, payload, this.secret_key);
			
			return token;
		},

		async uploadAnnouncementOrderFunc(file) {
			const formData = new FormData();
			formData.append('file', file);

			const request = await axios.post(`${this.url}/purchase_planning/upload_file`, formData);
			const response = request.data;
			return response;
		},

		async deleteAnnouncementOrderFunc(title) {
			const payload = {
				filename: title
			};

			const token = this.generate_token(payload);
			const formData = new FormData();
			formData.append('token', token);

			const request = await axios.post(`${this.url}/purchase_planning/delete_file`, formData);
			const response = request.data;
			return response;
		},

		async jobs_show() {
			const request = await axios.get(`${this.url}/jobs_show`);
			const response = await request.data;
			return response;
		},

		async delete_job_execute(id) {
			const payload = {
				id: id
			};
			console.log(payload);

			const header = {alg: 'HS256', typ: 'JWT'};
			const token = KJUR.jws.JWS.sign("HS256", header, payload, this.secret_key);
			const formData = new FormData();
			formData.append("token", token);

			const request = await axios.post(`${this.url}/delete_job`, formData);
			const response = await request.data;
			return response;
		},

		async add_new_job(job_id, title, description) {
			const payload = {
				job_id: job_id,
				title: title,
				description: description
			};

			const header = {alg: 'HS256', typ: 'JWT'};
			const token = KJUR.jws.JWS.sign("HS256", header, payload, this.secret_key);
			const formData = new FormData();
			formData.append("token", token);

			const request = await axios.post(`${this.url}/add_new_job`, formData);
			const response = await request.data;
			return response;
		},

		async select_back_session() {
			const request = await axios.get(`${this.url}/session`);
			const response = await request.data;
			return response;
		},

		async all_debtors() {
			const request = await axios.get(`${this.url}/debtors`);
			const response = await request.data;
			return response;
		},

		async debtors_mail(data) {
			const formData = new FormData();

			const payload = {
				data: data
			}

			const headers = {
				'Content-Type': 'application/json'
			}

			const request = await axios.post(`${this.url}/debtors/mail`, payload, { headers });
			const response = await request.data;
			return response;
		},

		async debtors_phone_mail(name, email, number, account, debt) {
			const data = JSON.stringify({
				number: number,
				account: account,
				debt: debt
			});

			const headers = {
				"x-api-key": this.aws_sns_api_key
			};

			const request = await axios.post(`${this.aws_sns_api_url}`, data, { headers });
			const response = await request.data;
			return response;
		},

		async deleteMyProfileFunc(userId) {
			const request = await axios.delete(`${this.url}/user/profile/delete/${userId}`);
			const response = await request.data;
			return response;
		},

		async showMailsFunc() {
			const request = await axios.get(`${this.url}/mails`);
			const response = await request.data;
			return response;
		},

		async showOrdersFunc() {
			const request = await axios.get(`${this.url}/orders/get`);
			const response = await request.data;
			return response;
		},

		async showAnnouncementOrdersFunc() {
			const request = await axios.get(`${this.url}/purchase_planning/select_files`);
			const response = await request.data;
			return response;
		},

		async uploadOrderFunc(file) {
			const formData = new FormData();
			formData.append('file', file);

			const request = await axios.post(`${this.url}/orders/upload`, formData);
			const response = await request.data;
			return response;
		},

		async deleteOrderFunc(id) {
			const request = await axios.delete(`${this.url}/orders/delete/${id}`);
			const response = await request.data;
			return response;
		},

		async showUsersFunc() {
			const request = await axios.get(`${this.url}/admin/users`);
			const response = await request.data;
			return response;
		},

		async answerMailFunc(email, message) {
			const formData = new FormData();
			formData.append('email', email);
			formData.append('message', message);

			const request = await axios.post(`${this.url}/send/answer`, formData);
			const response = await request.data;
			return response;
		},

		async invoiceFunc(card, card_exp_month, card_exp_year, card_cvv, name, email, phone, account_id, city, service, amount) {
			const formData = new FormData();

			formData.append('card', card);
			formData.append('card_exp_month', card_exp_month);
			formData.append('card_exp_year', card_exp_year);
			formData.append('card_cvv', card_cvv);
			formData.append('name', name);
			formData.append('email', email);
			formData.append('phone', phone);
			formData.append('account_id', account_id);
			formData.append('city', city);
			formData.append('service', service);
			formData.append('amount', amount);

			const request = await axios.post(`${this.url}/invoice`, formData);
			const response = await request.data;
			return response;
		},

		async selectAccountsFunc(id) {
			const formData = new FormData();
			formData.append('id', id);

			const request = await axios.post(`${this.url}/admin/accounts`, formData);
			const response = await request.data;
			return response;
		},

		async deleteMessageFunc(idMail) {
			const request = await axios.delete(`${this.url}/mail/delete/${idMail}`);
			const response = await request.data;
			return response;
		},

		async createArticleFunc(title, text, type, images) {
			const formData = new FormData();
			for(var image of images) {
				formData.append('images', image);
			}

			formData.append('title', title);
			formData.append('text', text);
			formData.append('articleType', type);

			const request = await axios.post(`${this.url}/articles`, formData);
			const response = await request.data;
			return response;
		}, 

		async uploadAvatar(email, file) {
			const formData = new FormData();
			formData.append('email', email);
			formData.append('file', file);

			const request = await axios.post(`${this.url}/user/avatar/upload`, formData);
			const response = await request.data;
			return response;
		},

		async profileUpdateFunc(current_email, user) {
			const formData = new FormData();
			formData.append('current_email', current_email);
			formData.append('f', user.f);
			formData.append('e', user.e);
			formData.append('p', user.p);
			formData.append('phone', user.phone);

			const request = await axios.post(`${this.url}/user/profile/update`, formData);
			const response = await request.data;
			return response;
		},

		async banksUpdateFunc(current_email, user) {
			const formData = new FormData();
			formData.append('current_email', current_email);
			formData.append('card', user.card);
			formData.append('card_exp_month', user.card_exp_month);
			formData.append('card_exp_year', user.card_exp_year);
			formData.append('card_cvv', user.card_cvv);

			const request = await axios.post(`${this.url}/user/banks/update`, formData);
			const response = await request.data;
			return response;
		},

		async deleteAvatarFunc(email) {
			const formData = new FormData();
			formData.append('email', email);

			const request = await axios.post(`${this.url}/user/avatar/delete`, formData);
			const response = await request.data;
			return response;
		},

		async updateArticleFunc(currentyTitle, title, text, type, images) {
			const formData = new FormData();
			
			if(images.length > 0) {
				for(var image of images) {
					formData.append('images', image);
				}
			}

			formData.append('currentTitle', currentyTitle);
			formData.append('title', title);
			formData.append('text', text);
			formData.append('articleType', type);

			const request = await axios.put(`${this.url}/article/update`, formData);
			
			var response = await request.data;
			return response;
		},

		async deleteArticleFunc(currentTitle) {
			const formData = new FormData();
			formData.append('currentTitle', currentTitle);

			const request = await axios.post(`${this.url}/article/delete`, formData);
			const response = await request.data;
			return response;
		},

		async deleteArticleImage(currentTitle, filename) {
			const formData = new FormData();
			formData.append('currentTitle', currentTitle);
			formData.append('filename', filename);

			const request = await axios.post(`${this.url}/articles/image/delete`, formData);
			const response = await request.data;
			return response;
		},

		async getArticleFunc(title) {
			const formData = new FormData();
			formData.append('title', title);

			const request = await axios.post(`${this.url}/article/show`, formData);
			const response = await request.data;
			return response;
		},

		async getAllArticlesFunc(articleType=null) {
			const request = articleType === null ? await axios.get(`${this.url}/articles`) : await axios.get(`${this.url}/articles?articleType=${articleType}`);
			const response = await request.data;
			return response;
		},

		async deleteAllArticlesFunc() {
			const request = await axios.delete(`${this.url}/articles`);
			const response = await request.data;
			return response;
		},

		async searcherFunc(q) {
			const formData = new FormData();
			formData.append('q', q);

			const request = await axios.post(`${this.url}/search/articles`, formData);
			const response = await request.data;
			return response;
		},

		async auth(p, e) {
			const headers = {
				'Content-Type': 'application/json'
			};

			const formData = new FormData();
			formData.append('p', p);
			formData.append('e', e);

			const request = await axios.post(`${this.url}/user/auth`, formData, {headers});
			const response = await request.data;
			return response;
		},

		async signUp(f, e) {
			const headers = {
				'Content-Type': 'application/json'
			};

			const formData = new FormData();
			formData.append('f', f);
			formData.append('e', e);

			const request = await axios.post(`${this.url}/user`, formData, {headers});
			const response = await request.data;
			return response;
		},

		async resetPassword(e) {
			const headers = {
				'Content-Type': 'application/json'
			};

			const formData = new FormData();
			formData.append('e', e);

			const request = await axios.post(`${this.url}/user/reset/password`, formData, {headers});
			const response = await request.data;
			return response;
		},

		async sendMail(fullname, email, phone, message) {
			const request = await axios.post(`${this.url}/send?fullname=${fullname}&email=${email}&phone=${phone}&message=${message}`);
			const response = await request.data;
			return response;
		},

		async payments(userId) {
			const request = await axios.get(`${this.url}/user/payments?userId=${userId}`);
			const response = await request.data;
			return response;
		},

		async payment(account) {
			const request = await axios.get(`${this.url}/user/payment?account=${account}`);
			const response = await request.data;
			return response;
		},

		async create_payment(userId, city, service, account, address, house, flat) {
			const headers = {
				'Content-Type': 'application/json'
			};

			const formData = new FormData();
			formData.append('userId', userId);
			formData.append('city', city);
			formData.append('service', service);
			formData.append('account', account);
			formData.append('address', address);
			formData.append('address_house', house);
			formData.append('address_flat', flat);

			const request = await axios.post(`${this.url}/user/payment/create`, formData, {headers});
			const response = await request.data;
			return response;
		},

		async delete_payment(id) {
			const request = await axios.delete(`${this.url}/user/payment/delete?id=${id}`);
			const response = await request.data;
			return response;
		},

		async importExcelFunc(file, city) {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('city', city);
			const request = await axios.post(`${this.url}/import`, formData);
			const response = await request.data;
			return response;
		}
	}
}