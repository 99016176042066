<template>
  <div class="modal d-block" tabindex="-1" >
    <div class="modal-dialog d-flex align-items-center justify-content-center modal-dialog-scrollable">
      <div class="modal-content captcha-modal text-dark">
        <div class="modal-header pb-1">
          <h4 class="modal-title">РеКаптча: {{ question_string }}</h4>
          <button type="button" class="btn-close btn-close-dark" data-bs-dismiss="modal" aria-label="Close" @click="captcha.modal = false"></button>
        </div>
        <div class="modal-body p-0 pb-3">
          <div v-if="loader" class="d-flex align-items-center justify-content-center w-100">
            <div class="spinner-border" style="width: 4rem; height: 4rem;" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>

          <!-- <div class="captcha-container w-100">

          </div> -->
          <div class="d-flex align-items-center justify-content-center" v-if="!loader">
            <div class="mx-4 p-3 number_first" @click="continueAuth(numbers.first)">
              <p class="fs-3 m-0 text-decoration-underline continue_auth__p">{{ numbers.first }}</p>                  
            </div>
            <div class="mx-4 p-3 number_second d-flex align-items-center justify-content-center" @click="continueAuth(numbers.second)">
              <p class="fs-3 m-0 text-decoration-underline continue_auth__p">{{ numbers.second }}</p>
            </div>
            <div class="mx-4 p-3 number_third d-flex align-items-center justify-content-center" @click="continueAuth(numbers.third)">
              <p class="fs-3 m-0 text-decoration-underline continue_auth__p">{{ numbers.third }}</p>
            </div>
            <div class="mx-4 p-3 number_fourth d-flex align-items-center justify-content-center" @click="continueAuth(numbers.fourth)">
              <p class="fs-3 m-0 text-decoration-underline continue_auth__p">{{ numbers.fourth }}</p>
            </div>
          </div>
          <!-- <button class="btn btn-outline-dark w-100 btn-lg btn-block" @click="continueAuth" type="submit" :disabled="disabled">Відправити</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toastr from 'toastr';
import 'toastr/toastr.scss';
import auth from '@/mixins/api.js';

export default {
    name: "CaptchaModal",
    mixins: [auth],
    props: {
      blockAllButtons: Function,
      unBlockAllButtons: Function,
      captcha: Object,
      toastr: Object,
      authUser: Function
    },
    data() {
      return {
        question: null,
        question_string: null,
        answer: null,
        disabled: false,
        loader: false,
        numbers: {
          first: 0,
          second: 0,
          third: 0,
          fourth: 0
        }
      }
    },
    mounted() {
      this.generateQuestion();

      // this.fullingContent();
    },
    methods: {
      async fullingContent() {
        const container = document.querySelector('.captcha-container');
        AwsWafCaptcha.renderCaptcha(container, {
          apiKey: "cG/sshNAXvvwrkgnmKXqm1u7wKp32pQN9BQHdkUIxdzYghCaJ/XN8HV6aCoBJ7Mz64b8rpL4PUMD7w6IX6D60ZdhT8ZLdlCi18xqGt3hnqDmoLwu+i8ik5qtbasEViDU7+eEA7daNtTl0lb1ODlj67cf2Q2RQTkPabtzXIgnpvLeuAC+iZLeFUqzj5fgoWSZgWUkP1h3oT1dUMHkto0IbFdRpqFrjeKEgAc0+9YM4/yuAwxYPml7msP3LXMddVjDQ/A1qFyFePWGTjoGz0VxPVBv/k/3gQcc9H1R77ZgR1e4Z7VF+jHa62t4Um+EPiOhEBLdPAEoUogTeykyRUTL4ofR3dyugSjB8gQG+Ex7q6yGnwHJsZzQIkM0DUyY2I+9q45tybQfSXd31JaHMpWVExYkvUjFsQD1+WGgFDUDs/5OR39xi7f1jqCr7F5MCjTxdKL3X6AFQk/e9o+JUFMP6/o0bgH68M+oZA7K3DDTWvw+rq1LozfldokCQOtsXJiWHRF75LfK4rIecMXanRDYG0jAwk01eZ/2lqeTTORjTUdCmKB3T35LWhb5Iii0lh56p2hJrJiukpS+DHjr1DyAnqIMXoXIJdPyU3cxiJNg+fcntIvebySEm0xQJIYRiRUO2yKhbuW8x/L/mhJm29ghp289UUEFUmShqq/SECdFd6E=_0_1",
          onSuccess: () => {
              //
          },
          onError: (error) => {
            toastr.error(error)
          }, 
          onLoad: () => {
            this.loader = false;
          },
          onPuzzleTimeout: () => {
            this.loader = true
          },
          onPuzzleCorrect: () => {
            this.continueAuth();
          },
          onPuzzleIncorrect: () => {
            toastr.error('Ваша відповідь неправильна!')
            this.loader = true
            this.fullingContent()
          },
          skipTitle: false,
          disableLanguageSelector: true,
        });
      },
      randomNumber(n=9) {
        var numbers = this.numbers;
        var receive = Math.round(Math.random() * n);
        if([numbers.first, numbers.second, numbers.third, numbers.fourth].includes(receive)) {
          return this.randomNumber(n);
        }

        return receive;
      },
      generateQuestion() {
        var numbers = this.numbers;

        var numberOne = this.randomNumber();
        numbers.first = numberOne;

        var numberTwo = this.randomNumber();
        numbers.second = numberTwo;

        var numberThree = this.randomNumber();
        numbers.third = numberThree;

        var numberFour = this.randomNumber();
        numbers.fourth = numberFour;

        var numbers_array = [numberOne, numberTwo, numberThree, numberFour];

        var key = numbers_array[this.randomNumber(3)];

        this.question_string = `виберіть число ${key}`;

        return this.question = key;
      },
      continueAuth(answer) {
        var p = document.querySelector('.password');
        var e = document.querySelector('.userEmailAuth');
        var flexCheckDefault = document.querySelector('#flexCheckDefault');
        var auth = document.querySelector('.auth');
        var forgetPassword = document.querySelector('.forgetPassword');
        var registration = document.querySelector('.registration');

        if(answer == this.question) {
          this.disabled = true;
          this.blockAllButtons();
          document.querySelector('.cs-loader').classList.remove('d-none');
          document.querySelector('.modal').classList.remove('d-block');

          setTimeout(async () => {
            try {
              const request = await this.auth(p.value, e.value);
              if(request.status === 200) {
                var storage = sessionStorage.getItem('accounts_page');
                if(storage !== null) {
                   sessionStorage.removeItem('accounts_page');
                }

                if(flexCheckDefault.checked === true) {
                  localStorage.setItem('p', p.value);
                  localStorage.setItem('e', e.value);
                }

                if(request.result.r === 29) {
                  sessionStorage.setItem('admin', true);
                  sessionStorage.setItem('user', 'Адмін');
                } else {
                  var ads_show = request.ads_show;
                  var ads_href = request.ads_href;

                  if(ads_show !== false) {
                    sessionStorage.setItem('ads_href', ads_href);
                  }

                  sessionStorage.setItem('user', request.result.f);
                  sessionStorage.setItem('userFullInfo', JSON.stringify(request.result));
                }

                this.authUser();
              } else {
                toastr.error(request.result);
              }
            } catch(error) {
              toastr.error(error)
              console.error(error)
            }

            this.captcha.modal = false;
            document.querySelector('.cs-loader').classList.add('d-none');
          }, 900)
        } else {
          toastr.error('Невірно! Спробуйте ще раз!');
          this.generateQuestion();
        }

        /* this.disabled = true;
        this.blockAllButtons();
        document.querySelector('.cs-loader').classList.remove('d-none');
        document.querySelector('.modal').classList.remove('d-block');

        setTimeout(async () => {
          try {
            const request = await this.auth(p.value, e.value);
            if(request.status === 200) {

              var storage = sessionStorage.getItem('accounts_page');
              if(storage !== null) {
                 sessionStorage.removeItem('accounts_page');
              }

              if(flexCheckDefault.checked === true) {
                localStorage.setItem('p', p.value);
                localStorage.setItem('e', e.value);
              }

              if(request.result.r === 29) {
                sessionStorage.setItem('admin', true);
                sessionStorage.setItem('user', 'Адмін');
              } else {
                var ads_show = request.ads_show;
                var ads_href = request.ads_href;

                if(ads_show !== false) {
                  sessionStorage.setItem('ads_href', ads_href);
                }

                sessionStorage.setItem('user', request.result.f);
                sessionStorage.setItem('userFullInfo', JSON.stringify(request.result));
              }

              this.authUser();
            } else {
              toastr.error(request.result);
            }
          } catch(error) {
            toastr.error(error)
            console.error(error)
          }

          this.captcha.modal = false;
          document.querySelector('.cs-loader').classList.add('d-none');
        }, 900) */

        this.unBlockAllButtons();
      }
    }
}
</script>

<style>
  .continue_auth__p {
    cursor: pointer;
  }

  .continue_auth__p:hover {
    color: green;
  }
</style>