<template>
  <div class="ProfilePage">
    <div>
      <BackSwitcherButtonComponent />
      <UpButtonComponent />
      <PromStatePurchase />

      <div class="px-4 py-5 pb-1 border-bottom text-center bg-gradient text-white title-block">
        <img class="d-block mx-auto mb-4 border border-white border-2 rounded p-1 shadow-lg" src="../assets/logo.jpg" alt="" width="100" height="100">
        <h1 class="display-6 fw-bold">Комунальне підприємство</h1>
        <h3 class="display-7 fw-bold">"ЖИЛСЕРВІС"</h3>
        <p class="mb-0">Черкаської</p>
        <p class="mb-0">селищної ради</p>
        <header class="p-3 mt-5">
          <div class="container">
            <hr class="shadow" />
            <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
              <ul class="nav d-none d-sm-flex col-12 me-lg-auto justify-content-center mb-md-0">
                <li><a class="btn btn-warning m-2 text-white border border-white" @click='toMainPage'>Назад до головної</a></li>
              </ul>

              <div class="burger d-flex d-sm-none flex-wrap w-100 align-items-center justify-content-center">
                <button class="btn btn-success border border-1 border-white btn-lg w-25 burger-btn text-white" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  ≡
                </button>
                <ul class="dropdown-menu dropdown-menu-light text-center">
                  <li class=""><span class="dropdown-item fw-bold" @click='toMainPage'>Назад до головної</span></li>
                </ul>
              </div>
            </div>
          </div>
        </header>
      </div>

      <div class="container rounded bg-white mt-5 mb-5">
        <div class="row p-3">
            <div class="col-md-6 border-right">
                <div class="avatar-block d-flex flex-column align-items-center justify-content-center h-100 text-center p-3 py-5">
                  <img class="avatar border border-white rounded-2" width="350" @click="avatar" :src="user.avatar">
                  <button v-if="user.avatar.split('/').reverse()[0] !== 'avatar.png'" class="btn btn-outline-danger mt-3" @click="deleteAvatar" title="Видалити зображення">🗑</button>
                </div>
            </div>
            <div class="col-md-6 border-right">
                <div class="p-3 py-5">
                    <div class="d-flex justify-content-center align-items-center mb-3">
                        <h4 class="text-center">Налаштування профілю</h4>
                    </div>
                    <div class="mt-2 d-flex justify-content-center align-items-center">
                        <div class="w-75">
                          <div><label class="labels">Ім'я та прізвище</label><input type="text" class="form-control" placeholder="" v-model="user.f"></div>
                          <div><label class="labels">Пароль</label><input type="password" class="form-control" placeholder="" v-model="user.p"></div>
                          <div><label class="labels">Номер телефону</label><input type="text" class="form-control" placeholder="" v-model="user.phone"></div>
                          <div><label class="labels">Пошта</label><input type="text" class="form-control" placeholder="" v-model="user.e"></div>
                        </div>
                    </div>
                    <div class="mt-5 text-center"><button class="btn btn-primary profile-button" type="button" @click.prevent="profileUpdate">Зберегти зміни</button></div>
                </div>
                <!-- <div class="p-3 py-5">
                    <div class="d-flex justify-content-center align-items-center mb-3">
                        <h4 class="text-center">Реквізити банківського рахунку</h4>
                    </div>
                    <div class="mt-2 d-flex justify-content-center align-items-center">
                        <div class="w-75">
                          <div><label class="labels">Номер карти</label><input type="text" class="form-control" placeholder="" v-model="user.card"></div>
                          <div><label class="labels">Термін придатності: місяць</label><input type="number" min="1" max="12" class="form-control" placeholder="" v-model="user.card_exp_month"></div>
                          <div><label class="labels">Термін придатності: рік</label><input type="number" :min="currenty_year" class="form-control" placeholder="" v-model="user.card_exp_year"></div>
                          <div><label class="labels">CVV</label><input type="text" class="form-control" placeholder="" v-model="user.card_cvv"></div>
                        </div>
                    </div>
                    <div class="mt-5 text-center"><button class="btn btn-primary profile-button" type="button" @click.prevent="banksUpdate">Зберегти зміни</button></div>
                </div> -->
            </div>
        </div>
      </div>

      <div class="cs-loader d-none fixed-bottom">
        <div class="cs-loader-inner">
          <label>●</label>
          <label>●</label>
          <label>●</label>
          <label>●</label>
          <label>●</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UpButtonComponent from './UpButtonComponent.vue';
import BackSwitcherButtonComponent from './BackSwitcherButtonComponent.vue';
import PromStatePurchase from './PromStatePurchase.vue';
import uploadAvatar from '@/mixins/api.js';
import deleteAvatarFunc from '@/mixins/api.js';
import profileUpdateFunc from '@/mixins/api.js';
import banksUpdateFunc from '@/mixins/api.js';
import toastr from 'toastr';
import 'toastr/toastr.scss';

export default {
  name: 'ProfilePage',
  mixins: [uploadAvatar, deleteAvatarFunc, profileUpdateFunc, banksUpdateFunc],
  components: {
    BackSwitcherButtonComponent, UpButtonComponent, PromStatePurchase
  },
  data() {
    return {
      user: null,
      currenty_year: new Date().getFullYear()
    }
  },
  created() {
    this.auth();
  },
  mounted() {
    this.theme();
    toastr.options = {
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-top-center',
      timeOut: 1600,
      toastClass: 'custom-toast',
    }
  },
  methods: {
    disabledMenu() {
      document.querySelector('#app').style.pointerEvents = "none";
      document.querySelector('.cs-loader').classList.remove('d-none');
    },
    unDisabledMenu() {
      document.querySelector('#app').style.pointerEvents = "auto";
      document.querySelector('.cs-loader').classList.add('d-none');
    },
    theme() {
      const titleBlock = document.querySelector('.title-block');
      const upBlock = document.querySelector('.up-button');
      const burger = document.querySelector('.burger-btn');
      const authModalButton = document.querySelector('#authModalButton');

      const needEdit = [titleBlock, upBlock, authModalButton, burger];

      const color = localStorage.getItem('theme');
      var bg = 'bg-success';

      if(color !== null) {
        if(color == 2) {
          bg = 'bg-secondary';
        } else if(color == 3) {
          bg = 'bg-dark';
        }
      }

      needEdit.forEach(e => {
        if(e !== null) {
          e.classList.add(bg);
        }
      })
    },
    auth() {
      const user = sessionStorage.getItem('userFullInfo');

      if(!user) {
        return this.$router.push({ name: 'welcome' });
      } 

      this.user = JSON.parse(user);
      return sessionStorage.setItem('current_email', this.user.e);
    },
    toMainPage() {
      return this.$router.push({name: 'welcome'})
    },
    avatar() {
      const input = document.createElement('input');
      input.type = "file";
      input.classList.add('invisible');
      document.body.append(input);

      input.click();

      input.addEventListener('change', async e => {
        if(input.files.length !== 0) {
          await this.disabledMenu();
          const file = input.files[0];
          const email = sessionStorage.getItem('current_email');

          try {
            const request = await this.uploadAvatar(email, file);
            const status = request.status;
            const message = request.result;

            if(status === 200) {
              const user = request.user;
              user.phone === 'null' ? user.phone = null : false;
              sessionStorage.setItem('userFullInfo', JSON.stringify(user));

              toastr.options.onHidden = () => {window.location.reload()};
              toastr.success(message)
            } else {
              toastr.error(message);
              await this.unDisabledMenu()
            }
          } catch (error) {
            console.error(error);
            toastr.error("Упс! Перевірте з'єднання з інтернетом або спробуйте ще раз трішки пізніше!");
            await this.unDisabledMenu()
          }
        } else {
          toastr.info('Зображення не вибрано!');
          await this.unDisabledMenu()
        }
      })

      document.body.removeChild(input);
    },
    async deleteAvatar() {
      await this.disabledMenu();

      try {
        const email = sessionStorage.getItem('current_email');
        const request = await this.deleteAvatarFunc(email);
        const status = request.status;
        const message = request.result;

        if(status === 200) {
          const user = request.user;
          user.phone === 'null' ? user.phone = null : false;
          sessionStorage.setItem('userFullInfo', JSON.stringify(user));

          toastr.options.onHidden = () => {window.location.reload()};
          toastr.success(message)
        } else {
          toastr.error(message);
          await this.unDisabledMenu()
        }
      } catch (error) {
        console.error(error);
        toastr.error("Упс! Перевірте з'єднання з інтернетом або спробуйте ще раз трішки пізніше!");
        await this.unDisabledMenu()
      }
    },
    async profileUpdate() {
      const f = this.user.f;
      const e = this.user.e;
      const p = this.user.p;
      const phone = this.user.phone !== null ? this.ifValidPhone(this.user.phone.replaceAll(' ', '')) : null
      const current_email = sessionStorage.getItem('current_email')

      if(f.replaceAll(' ', '').length !== 0 && this.ifValidEmail(e.replaceAll(' ', '')) && p.replaceAll(' ', '').length > 7 && (phone === true || phone === null) && current_email !== null) {
        await this.disabledMenu();

        try {
          const user = this.user;
          const request = await this.profileUpdateFunc(current_email, user);
          const status = request.status;
          const message = request.result;

          if(status === 200) {
            const user = request.user;
            user.phone === 'null' ? user.phone = null : false;
            sessionStorage.setItem('userFullInfo', JSON.stringify(user));
            sessionStorage.setItem('user', user.f);

            toastr.options.onHidden = () => {window.location.reload()};
            toastr.success(message)
          } else {
            toastr.error(message);
            await this.unDisabledMenu()
          }
        } catch (error) {
          console.error(error);
          toastr.error("Упс! Перевірте з'єднання з інтернетом або спробуйте ще раз трішки пізніше!");
          await this.unDisabledMenu()
        }
      } else {
        toastr.info('Заповніть усі поля коректно!');
      }
    },
    async banksUpdate() {
      const card = this.user.card;
      const card_exp_month = this.user.card_exp_month;
      const card_exp_year = this.user.card_exp_year;
      const card_cvv = this.user.card_cvv;

      const current_email = sessionStorage.getItem('current_email')

      if(
          (card !== null && card.replaceAll(' ', '').length === 16) && 
          (card_exp_month !== null && card_exp_month > 0) && 
          (card_exp_year !== null && card_exp_year >= this.currenty_year) && 
          (card_cvv !== null && card_cvv.replaceAll(' ', '').length === 3) &&
          current_email !== null
        ) {
        await this.disabledMenu();

        try {
          const user = this.user;
          const request = await this.banksUpdateFunc(current_email, user);
          const status = request.status;
          const message = request.result;

          if(status === 200) {
            const user = request.user;
            sessionStorage.setItem('userFullInfo', JSON.stringify(user));
            sessionStorage.setItem('user', user.f);

            toastr.options.onHidden = () => {window.location.reload()};
            toastr.success(message)
          } else {
            toastr.error(message);
            await this.unDisabledMenu()
          }
        } catch (error) {
          console.error(error);
          toastr.error("Упс! Перевірте з'єднання з інтернетом або спробуйте ще раз трішки пізніше!");
          await this.unDisabledMenu()
        }
      } else {
        toastr.info('Заповніть усі поля коректно!');
      }
    },
    ifValidEmail(email) {
      var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    ifValidPhone(phone) {
      const phoneNumber = phone.replace(/\s/g, '');
      const phoneRegex = /^(?:\+380|380|0)\d{9,12}$/;

      return phoneRegex.test(phoneNumber);
    }
  }
}
</script>

<style>
.form-control:focus {
    box-shadow: none;
    border-color: green;
}

.profile-button {
    box-shadow: none;
    border: none
}

.back:hover {
    color: green;
    cursor: pointer
}

.labels {
    font-size: 11px
}

.avatar {
  cursor: pointer;
}
</style>