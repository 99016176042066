<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
  body {
    background: #E8E8E8;
  }
</style>

