<template>
  <div class="modal d-block" v-if="$store.getters.getConfirmedWindowState" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Видалення профілю</h5>
        </div>
        <div class="modal-body">
          <p class="m-0">Ви впевнені, що бажаєти видалити свій профіль?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="deleteMyProfile(userId)">Так</button>
          <button type="button" class="btn btn-danger" @click="closeWindowFunc">Ні</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import deleteMyProfileFunc from '@/mixins/api.js';
import toastr from 'toastr';
import 'toastr/toastr.scss';

export default {
    name: "ConfirmedWindow",
    mixins: [deleteMyProfileFunc],
    props: {
      userId: Number,
      toastr: Object,
      blockAllButtons: Function,
      unBlockAllButtons: Function,
    },
    methods: {
      deleteUserInfo() {
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("userFullInfo");
        sessionStorage.removeItem("current_email");

        window.location.reload();
      },

      async deleteMyProfile(userId) {
        this.closeWindowFunc();
        this.blockAllButtons();
        document.querySelector('.cs-loader').classList.remove('d-none');

        try {
          const request = await this.deleteMyProfileFunc(userId);
          if(request.status === 200) {
            return this.deleteUserInfo();
          } else {
            toastr.error(request.message)
          }
        } catch(error) {
          toastr.error(error)
          console.error(error)
        }

        this.unBlockAllButtons();
        document.querySelector('.cs-loader').classList.add('d-none');
      },
      closeWindowFunc() {
        this.$store.commit('editConfirmedWindowState');
      },
    }
}
</script>