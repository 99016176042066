<template>
  <div class="modal d-block" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">Онлайн чат</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="chat.modal = false"></button>
        </div>
        <iframe class="border shadow bg-body-tertiary rounded-2" id="chat" src="https://d39v0ij4w7kr6q.cloudfront.net" frameborder="0"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatModal",
  props: {
    chat: Object
  },
}
</script>