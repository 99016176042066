<template>
  <div v-if="loader">
    <LoaderView  />
  </div>

  <div class="WelcomePage d-none">
    <BackSwitcherButtonComponent />
    <UpButtonComponent />
    <PromStatePurchase />
    <div class="px-4 py-5 pb-1 border-bottom text-center bg-gradient text-white title-block">
      <img class="d-block mx-auto mb-4 border border-white border-2 rounded p-1 shadow-lg" src="../assets/logo.jpg" alt="" width="100" height="100">
      <h1 class="display-6 fw-bold">Комунальне підприємство</h1>
      <h3 class="display-7 fw-bold">"ЖИЛСЕРВІС"</h3>
      <p class="mb-0">Черкаської</p>
      <p class="mb-0">селищної ради</p>
      <header class="p-3 mt-5">
        <div class="container">
          <hr class="shadow m-0" />
          <div class="mt-1 d-flex flex-wrap align-items-center justify-content-center">
            <ul class="nav d-none d-sm-flex col-12 me-lg-auto mb-2 justify-content-center mb-md-0">
              <li class="nav-item"><button class="btn shadow border text-white m-3 authModalButton" id="authModalButton" @click='authModal'>Увійти до кабінету</button></li>
              <li class="nav-item"><button class="btn btn-outline-warning btn-warning shadow text-white border m-3 news" @click='news'>Новини</button></li>
              <li class="nav-item"><button class="btn btn-outline-warning shadow text-white border m-3 jobs" @click='jobs'>Вакансії</button></li>
              <li class="nav-item"><button class="btn btn-outline-warning shadow text-white border m-3 contacts" @click='contacts'>Контакти</button></li>
            </ul>

            <div class="mt-3 burger d-flex d-sm-none mb-2 flex-wrap w-100 align-items-center justify-content-center">
              <button class="btn border border-1 border-white btn-lg w-25 burger-btn text-white" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                ≡
              </button>
              <ul class="dropdown-menu dropdown-menu-light text-center">
                <li class=""><span class="dropdown-item authModalButton fw-bold" @click='authModal'>Увійти до кабінету</span></li>
                <li class=""><span class="dropdown-item news" @click='news'>Новини</span></li>
                <li class=""><span class="dropdown-item jobs" @click='jobs'>Вакансії</span></li>
                <li class=""><span class="dropdown-item contacts" @click='contacts'>Контакти</span></li>
              </ul>
            </div>

            <form class="d-flex" role="search">
              <input type="search" class="mx-1 form-control form-control-dark" placeholder="Пошук..." v-model='searcher_text'>
              <button class="mx-1 btn btn-outline-light btn-sm searcherSubmit" type="submit" @click='searcher'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="mx-3" role="img" viewBox="0 0 24 24"><title>Search</title><circle cx="10.5" cy="10.5" r="7.5"></circle><path d="M21 21l-5.2-5.2"></path></svg>
              </button>
              <button class="mx-1 btn btn-danger d-none btn-sm backAllArticles" type="submit" @click='backAllArticles'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <line x1="4" y1="4" x2="16" y2="16" stroke="white" stroke-width="2" />
                  <line x1="4" y1="16" x2="16" y2="4" stroke="white" stroke-width="2" />
                </svg>
              </button>
            </form>

            <!-- <div class="text-end">
              <button type="button" class="btn btn-outline-light me-2">Вхід</button>
            </div> -->
          </div>
        </div>
      </header>
    </div>

    <div class="album py-5 bg-body-tertiary">
      <div class="container">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 g-3 articles">
          
        </div>

        <div class='mt-4' v-if="['ContactComponent', 'JobsComponent'].includes(anotherPage)">
          <KeepAlive>
            <component :is="anotherPage" :jobs_list="jobs_list" />
          </KeepAlive>
        </div>
        <div class='personal-accounts' v-if='personal_accounts === true'>
          <PersonalAccountsPage :paymentsList='paymentsList' :blockAllButtons='blockAllButtons' :unBlockAllButtons='unBlockAllButtons'></PersonalAccountsPage>
        </div>
      </div>
      <nav v-if='pagination === true' class="d-flex pagination-nav align-items-center mt-5 justify-content-center" aria-label="Page navigation example">
        <ul class="pagination m-0">
          <li class="page-item">
            <a class="page-link" href="#" aria-label="Next" @click.prevent='next'>
              <span aria-hidden="true">
                <svg width="20px" height="20px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <polygon points="50,80 80,20 20,20" style="fill:black;stroke:black;stroke-width:3;"/>
                </svg>
              </span>
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#" aria-label="Prev" @click.prevent='prev'>
              <span aria-hidden="true">
                <svg width="20px" height="20px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <polygon points="50,20 80,80 20,80" style="fill:black;stroke:black;stroke-width:3;"/>
                </svg>
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </div>

    <div class="container border-top">
      <footer class="py-3">
        <ul class="nav justify-content-center border-bottom pb-3 mb-3">
          <li class="nav-item"><button class="nav-link m-2 news-footer" @click='news'>Новини</button></li>
          <li class="nav-item"><button class="nav-link m-2 news-footer" @click='jobs'>Вакансії</button></li>
          <li class="nav-item"><button class="nav-link m-2 contacts-footer" @click='contacts'>Контакти</button></li>
        </ul>
        <p class="text-center text-body-secondary mb-0">© {{ currentYear }} КП "ЖИЛСЕРВІС"</p>
      </footer>
    </div>

    <div class="cs-loader d-none fixed-bottom">
      <div class="cs-loader-inner">
        <label>●</label>
        <label>●</label>
        <label>●</label>
        <label>●</label>
        <label>●</label>
      </div>
    </div>

    <ConfirmedWindow :userId="userId" :toastr="toastr" :blockAllButtons="blockAllButtons" :unBlockAllButtons="unBlockAllButtons" />

    <div class="modal fade opacity-100">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
          </div>
          <div class="modal-body">
            <p class=''></p>

            <div id="carouselExample" class="carousel slide">
              <div class="carousel-inner">

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click='hideModal'>Приховати</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="captcha.modal">
    <CaptchaModal :authUser="authUser" :toastr="toastr" :blockAllButtons="blockAllButtons" :unBlockAllButtons="unBlockAllButtons" :captcha="captcha" />
  </div>

  <div v-if="debtors.modal">
    <DebtorsModal :users="debtors.users" />
  </div>

  <div v-if="debtAlert.modal === true" class="DebtModal">
    <DebtModal :debtAlert="debtAlert" />
  </div>
</template>

<script>
import toastr from 'toastr';
import 'toastr/toastr.scss';
import PersonalAccountsPage from './PersonalAccountsPage.vue';
import '../assets/styles/spinner.css';
import getAllArticlesFunc from '../mixins/api.js';
import searcherFunc from '../mixins/api.js';
import auth from '../mixins/api.js';
import signUp from '../mixins/api.js';
import sendMail from '../mixins/api.js';
import payments from '../mixins/api.js';
import create_payment from '../mixins/api.js';
import payment from '../mixins/api.js';
import delete_payment from '../mixins/api.js';
import resetPassword from '../mixins/api.js';
import UpButtonComponent from './UpButtonComponent.vue';
import PromStatePurchase from './PromStatePurchase.vue';
import ContactComponent from './ContactComponent.vue';
import JobsComponent from './JobsComponent.vue';
import ConfirmedWindow from './ConfirmedWindow.vue';
import BackSwitcherButtonComponent from './BackSwitcherButtonComponent.vue';
import CaptchaModal from '@/components/Modals/CaptchaModal.vue';
import DebtorsModal from '@/components/Modals/DebtorsModal.vue';
import DebtModal from '@/components/Modals/DebtModal.vue';
import LoaderView from '@/components/Loader.vue';
import all_debtors from '@/mixins/api.js';
import select_back_session from '@/mixins/api.js';
import jobs_show from '@/mixins/api.js';

export default {
  name: 'WelcomePage',
  mixins: [jobs_show, getAllArticlesFunc, searcherFunc, auth, signUp, resetPassword, sendMail, payments, create_payment, payment, delete_payment, all_debtors, select_back_session],
  components: { PersonalAccountsPage, UpButtonComponent, PromStatePurchase, ContactComponent, JobsComponent, ConfirmedWindow, BackSwitcherButtonComponent, CaptchaModal, DebtorsModal, LoaderView, DebtModal},
  data() {
    return {
      url: this.$store.getters.getUrl,
      currentYear: new Date().getFullYear(),
      searcher_text: '',
      modal: false,
      contucts: null,
      personal_accounts: false,
      paymentsList: null,
      pagination: false,
      articles: null,
      anotherPage: null,
      userId: null,
      captcha: {
        modal: false
      },
      debtors: {
        users: [],
        modal: false
      },
      loader: false,
      debtAlert: {
        modal: false,
        debts: []
      },
      jobs_list: [
      ],
    }
  },

  async mounted() {
    toastr.options = {
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-top-center',
        timeOut: 1600,
        toastClass: 'custom-toast',
    };

    
    await this.before_open_page();
    await document.querySelector('.WelcomePage').classList.toggle('d-none');

    const alert = sessionStorage.getItem('alert');

    if(alert !== null) {
      toastr.success(alert);
      sessionStorage.removeItem('alert');
    }

    this.theme();
    this.mountedFunc();
    this.deletePaginData();
  },

  updated() {
    this.deletePaginData();
  },

  created() {
    this.pagination = false;
  },

  methods: {
    async before_open_page() {
      // this.loader = true;

      // if(sessionStorage.getItem('debtors') === null) {
        // const debtors = this.debtors;

        // const debtors_reuqest = await this.debtorsFunc();

        // if(debtors.users.length !== 0) {
          // debtors.modal = true;
        // }
      // }

      this.loader = false;
    },

    async debtorsFunc() {
      try {
        const debtors = this.debtors.users;
        const request = await this.all_debtors();
        if(request['status'] === 200) {
          var debtors_filter = request['message'];
          debtors_filter.forEach(debtor => {
            if(debtors.length !== 0) {
              var debtor_exist = debtors.some(item => item['account'] === debtor['account'])
              if(debtor_exist === false) {
                debtors.push(debtor);
              }
              } else {
                debtors.push(debtor);
              }
          });

          sessionStorage.setItem('debtors', JSON.stringify(this.debtors.users))
        } else {
          toastr.error(request['message'])
        }
      } catch(error) {
        console.error(error)
        toastr.error('Боржники відсутні!')
      }
    },

    theme() {
      const titleBlock = document.querySelector('.title-block');
      const upBlock = document.querySelector('.up-button');
      const burger = document.querySelector('.burger-btn');
      const authModalButton = document.querySelector('#authModalButton');

      const needEdit = [titleBlock, upBlock, authModalButton, burger];

      const color = localStorage.getItem('theme');
      var bg = 'bg-success';

      if(color !== null) {
        if(color == 2) {
          bg = 'bg-secondary';
        } else if(color == 3) {
          bg = 'bg-dark';
        }
      }

      needEdit.forEach(e => {
        if(e !== null) {
          e.classList.add(bg);
        }
      })
    },

    next() {
      const className = 'd-none';
      var hiddenCount = 0;
      var visibility = false;

      if(this.articles !== null && this.articles.length > 0) {
        this.articles.forEach((card) => { 
          if (card.classList.contains(className) && hiddenCount < 2) {
            visibility = true;
            card.classList.remove(className);
            hiddenCount++;
          }
        });
      }
      
      if(hiddenCount === 1) {
        sessionStorage.setItem('page-single', true);
      }

      if(visibility === true) {
        if(sessionStorage.getItem('page')) {
           sessionStorage.setItem('page', parseInt(sessionStorage.getItem('page')) + 1);
        } else {
          sessionStorage.setItem('page', 1);
        }
      }

      window.scrollTo(0, document.body.scrollHeight);
    },

    prev() {
      const className = 'd-none';
      let hiddenCount = 0;

      if (sessionStorage.getItem('page') !== null && parseInt(sessionStorage.getItem('page')) > 0) {
        for (let i = this.articles.length - 1; i >= 0; i--) {
          const card = this.articles[i];
          if (hiddenCount < 2) {
            if (sessionStorage.getItem('page-single') !== null) {
              if (!card.classList.contains(className) && hiddenCount < 1) {
                card.classList.add(className);
                hiddenCount++;
              }
            } else {
              if (!card.classList.contains(className) && hiddenCount < 2) {
                card.classList.add(className);
                hiddenCount++;
              }
            }
          }
        }

        sessionStorage.removeItem('page-single');
      }

      if (sessionStorage.getItem('page')) {
        const value = parseInt(sessionStorage.getItem('page')) - 1 < 0 ? 0 : parseInt(sessionStorage.getItem('page')) - 1;
        sessionStorage.setItem('page', value);
      }
    },

    deletePaginData() {
      sessionStorage.removeItem('page');
      sessionStorage.removeItem('page-single');
    },

    mountedFunc() {
      const articleType = sessionStorage.getItem('currentyPage');
      if(articleType !== null) {
        if(articleType == 1) {
          return this.news();
        } else if(articleType == 2) {
          this.authUser();
          return this.jobs();
        } else if(articleType == 3) {
          this.authUser();
          return this.contacts();
        } else if(articleType == 4) {
          this.authUser();
          return this.toBillingsPage();
        }
      }
      
      return this.saveArticles();
    }, 

    authUser() {
      document.querySelector('.modal').classList.remove('d-block');
      this.ifUserAuth();
    },

    unDisabledAuthButton(p, e, auth) {
      if(p.value.replaceAll(' ', '').length < 8 || !this.ifValidEmail(e.value.replaceAll(' ', ''))) {
        auth.disabled = true;
      } else {
        auth.disabled = false;
      }
    },

    ifValidEmail(email) {
      var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

    unDisabledResetPasswordButton(name, password, email, resetPassword) {
      password = password !== null ? password.value.replaceAll(' ', '').length : null;
      name = name !== null ? name.value.replaceAll(' ', '').length : null;
      email = email !== null ? this.ifValidEmail(email.value.replaceAll(' ', '')) : null;

      if((password !== null && password < 8) || (name !== null && name === 0) || (email !== null && !email)) {
        resetPassword.disabled = true;
      } else {
        resetPassword.disabled = false;
      }
    },

    logOutFunc() {
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('admin');
      sessionStorage.removeItem('userFullInfo');
      sessionStorage.removeItem('usersDebtModal');
      sessionStorage.removeItem('current_email');
      this.authUser();
      this.news();
    },

    async resetPasswordFunc(e) {
      this.blockAllButtons();
      document.querySelector('.cs-loader').classList.remove('d-none');
      document.querySelector('.modal-content').style.pointerEvents = 'none';
      
      try {
        const request = await this.resetPassword(e);
        
        if(request.status === 200) {
          document.querySelector('.modal').classList.remove('d-block');
        }

        toastr.warning(request.result);
      } catch(error) {
        toastr.error(error);
        console.error(error)
      }

      this.unBlockAllButtons();
      document.querySelector('.modal-content').style.pointerEvents = 'auto';
      document.querySelector('.cs-loader').classList.add('d-none');
    },

    async signUpFunc(f, e) {
      this.blockAllButtons();
      document.querySelector('.cs-loader').classList.remove('d-none');
      document.querySelector('.modal').classList.remove('d-block');

      try {
        const request = await this.signUp(f.value, e.value);
        if(request.status === 200) {
          toastr.success(request.result)
        } else {
          toastr.error(request.result)
        }
      } catch(error) {
        toastr.error(error)
        console.error(error)
      }

      this.unBlockAllButtons();
      document.querySelector('.cs-loader').classList.add('d-none');
    },

    forgetPasswordContent() {
      document.querySelector('.modal-title').innerText = 'Відновлення паролю';
      document.querySelector('.modal-body > p').innerHTML = `
        <form class="mt-3">
          <div class="form-floating mt-2 mb-2">
            <input type="email" class="form-control userEmail" id="floatingInputEmail" placeholder="">
            <label for="floatingInputEmail">Пошта</label>
          </div>

          <div class='mt-4 w-100'>
            <button class="btn btn-outline-danger w-50 py-2 resetPassword" type="submit" disabled>Відновити пароль</button>
            <button class="btn btn-outline-primary py-2 signIn" type="submit">Увійти</button>
          <div>
        </form>
      `
      
      var email = document.querySelector('.userEmail');
      var resetPassword = document.querySelector('.resetPassword'); 
      var signIn = document.querySelector('.signIn'); 

      email.addEventListener('input', () => this.unDisabledResetPasswordButton(null, null, email, resetPassword));

      resetPassword.onclick = (e) => {
        e.preventDefault();
        this.resetPasswordFunc(email.value);
      };

      signIn.onclick = (e) => {
        e.preventDefault();
        this.authModal();
      }
    },

    registrationContent() {
      document.querySelector('.modal-title').innerText = 'Реєстрація юзера';
      document.querySelector('.modal-body > p').innerHTML = `
        <form class="mt-3">
          <div class="form-floating mt-2 mb-2">
            <input type="fullname" class="form-control name" id="floatingInput" placeholder="">
            <label for="floatingInput">Ім'я та прізвище</label>
          </div>
          <div class="form-floating mt-2 mb-2">
            <input type="email" class="form-control userEmail" id="floatingInputEmail" placeholder="">
            <label for="floatingInputEmail">Пошта</label>
          </div>

          <div class='mt-4 w-100'>
            <button class="btn btn-outline-danger w-50 py-2 signUp" type="submit" disabled>Зареєструватися</button>
            <button class="btn btn-outline-primary text-center py-2 signIn" type="submit">Увійти</button>
            <a href='${this.url}/auth/google' class="btn btn-outline-primary w-25 py-1 mt-1 google_auth d-flex align-items-center justify-content-center" type="submit">
              <svg class="mx-1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35" height="35" viewBox="0 0 48 48">
              <path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20  s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039 l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
              </svg>
            </a>
          <div>
        </form>
      `

      var name = document.querySelector('.name');
      var email = document.querySelector('.userEmail');
      var signUp = document.querySelector('.signUp'); 
      var signIn = document.querySelector('.signIn'); 

      name.addEventListener('input', () => this.unDisabledResetPasswordButton(name, null, email, signUp));
      email.addEventListener('input', () => this.unDisabledResetPasswordButton(name, null, email, signUp));

      signUp.onclick = (e) => {
        e.preventDefault();
        this.signUpFunc(name, email);
      };

      signIn.onclick = (e) => {
        e.preventDefault();
        this.authModal();
      }
    },

    authModal() {
      const user = sessionStorage.getItem('user');
      document.querySelector('.carousel-inner').innerHTML = '';
      document.querySelector('.modal').classList.add('d-block');

      if(!user) {
        document.querySelector('.modal-title').innerText = 'Авторизація';
        document.querySelector('.modal-body > p').innerHTML = `
          <form class="mt-3">
            <div class="form-floating mt-2 mb-2">
              <input type="email" class="form-control userEmailAuth" id="floatingInputEmail" placeholder="">
              <label for="floatingInputEmail">Пошта</label>
            </div>
            <div class="form-floating mt-2 mb-2">
              <input type="password" class="form-control password" id="floatingPassword" placeholder="Password">
              <label for="floatingPassword">Пароль</label>
            </div>

            <div class="form-check text-start my-3">
              <input class="form-check-input" type="checkbox" value="remember-me" id="flexCheckDefault">
              <label class="form-check-label" for="flexCheckDefault">
                Запам'ятати мене
              </label>
            </div>

            <button class="btn btn-outline-primary w-25 py-2 auth" type="submit" disabled>Увійти</button>
            <button class="btn btn-outline-primary w-50 py-2 registration" type="submit">Зареєструватися</button>
            <a href='${this.url}/auth/google' class="btn btn-outline-primary w-25 py-1 mt-1 google_auth d-flex align-items-center justify-content-center" type="submit">
              <svg class="mx-1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35" height="35" viewBox="0 0 48 48">
              <path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20  s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039 l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
              </svg>
            </a>

            <div class="form-check text-start my-3 p-0">
              <a class='link-opacity-100 forgetPassword'>Забули пароль?</a>
            </div>
          </form>
        `;

        var p = document.querySelector('.password');
        var e = document.querySelector('.userEmailAuth');
        var flexCheckDefault = document.querySelector('#flexCheckDefault');
        var auth = document.querySelector('.auth');
        var forgetPassword = document.querySelector('.forgetPassword');
        var registration = document.querySelector('.registration');

        if(localStorage.getItem('p') && localStorage.getItem('e')) {
          flexCheckDefault.checked = true;
          auth.disabled = false;
          e.value = localStorage.getItem('e');
          p.value = localStorage.getItem('p');
        }

        auth.addEventListener('click', async (event) => {
          event.preventDefault();
          this.captcha.modal = true;
        })

        flexCheckDefault.addEventListener('change', () => {
          if(localStorage.getItem('p') && localStorage.getItem('e')) {
            localStorage.removeItem('p', p.value);
            localStorage.removeItem('e', e.value);
          } else {
            if(p.value.replaceAll(' ', '').length > 7 && this.ifValidEmail(e.value)) {
              localStorage.setItem('p', p.value);
              localStorage.setItem('e', e.value);
            }
          }
        });

        forgetPassword.addEventListener('click', async (e) => {
          e.preventDefault();
          this.forgetPasswordContent();
        })

        registration.addEventListener('click', async (e) => {
          e.preventDefault();
          this.registrationContent();
        })

        p.addEventListener('input', () => this.unDisabledAuthButton(p, e, auth));
        e.addEventListener('input', () => this.unDisabledAuthButton(p, e, auth));
      } else {
        const admin = sessionStorage.getItem('admin');
        document.querySelector('.modal-title').innerText = user;

        if(admin) {
          document.querySelector('.modal-body > p').innerHTML = `
            <div class="auth-wrapper mt-3">
              <div class="auth-content">
                <nav class="sidebar">
                  <ul class="nav flex-column fw-bold">

                    <li class="nav-item border shadow-sm m-1"><a href="#" class="toAdminPanel nav-link">До адмінки</a></li>
                    <li class="nav-item border shadow-sm m-1"><a href="#" class="logOut nav-link">Разлогінитись</a></li>
                  
                  </ul>
                </nav>
              </div>
            </div>
          `

          const toAdminPanel = document.querySelector('.toAdminPanel');

          toAdminPanel.onclick = (e) => {
            e.preventDefault();
            return this.$router.push({name: 'admin'});
          }
        } else {
          const modalWindow = document.querySelector('.modal-body > p');
          modalWindow.innerHTML = `
            <div class="auth-wrapper mt-3">
              <div class="auth-content">
                <nav class="sidebar">
                  <ul class="nav flex-column fw-bold">

                    <li class="nav-item border shadow-sm m-1"><a href="#" class="toBillings nav-link">Передивитись рахунки</a></li>
                    <li class="nav-item border shadow-sm m-1"><a href="#" class="addPaymentAccount nav-link">Додати рахунок</a></li>
                    <li class="nav-item border shadow-sm m-1"><a href="#" class="editProfile nav-link">Редагувати профіль</a></li>
                    <li class="nav-item border shadow-sm m-1"><a href="#" class="logOut nav-link">Разлогінитись</a></li>
                    <li class="nav-item border shadow-sm m-1"><a href="#" class="deleteMyself nav-link text-danger">Видалити профіль</a></li>
                  
                  </ul>
                </nav>
              </div>
            </div>
          `

          const toBillings = document.querySelector('.toBillings');
          const addPaymentAccount = document.querySelector('.addPaymentAccount');
          const editProfile = document.querySelector('.editProfile');
          const deleteMyself = document.querySelector('.deleteMyself');

          editProfile.onclick = (e) => {
            this.$router.push({name: 'profile'})
          }

          deleteMyself.onclick = (e) => {
            e.preventDefault();
            document.querySelector('.modal').classList.remove('d-block');
            this.$store.commit('editConfirmedWindowState');

            var userId = sessionStorage.getItem('userFullInfo');
            userId = JSON.parse(userId);
            userId = userId['id'];

            this.userId = userId;
          }

          toBillings.onclick = async (e) => {
            e.preventDefault();
            this.toBillingsPage();
          }

          addPaymentAccount.onclick = async (e) => {
            e.preventDefault();

            modalWindow.innerHTML = `
              <div class="auth-wrapper mt-3">
                <div class="auth-content">
                  <div class='form'>
                    <div class="mb-2">
                      <label for="exampleInputService1" class="form-label fw-bold">Виберіть послугу:</label>
                      <select class="form-select" id="exampleInputService1">
                        <!-- <option value="Водопостачання" selected>Водопостачання</option> -->
                        <option value="Побутові відходи">Побутові відходи</option>
                        <!-- <option value="Газопостачання">Газопостачання</option> -->
                        <option value="Тепломережа">Тепломережа</option>
                        <option value="Квартплата">Квартплата</option>
                      </select>
                    </div>
                    <div class="mb-2">
                      <label for="exampleInputAccount1" class="form-label fw-bold">Номер особового рахункy:</label>
                      <input type="text" class="form-control" id="exampleInputAccount1">
                    </div>
                    <div class="mb-2">
                      <label for="exampleInputAddress1" class="form-label fw-bold">Адреса:</label>
                      <select class="form-select" id="exampleInputAddress1">
                        <option value="Черкаське" selected>Черкаське</option>
                        <option value="Гвардійське">Гвардійське</option>
                      </select>
                      <input type="text" placeholder="Вулиця" class="form-control mt-2" id="street">
                      <input type="number" min="0" placeholder="Будинок" class="form-control mt-2" id="house">
                      <select class="form-select mt-2" id="exampleInputApartment1">
                        <option value="" selected disabled>Корпус</option>
                        <option value="">Відсутній</option>
                        <option value="А">А</option>
                        <option value="Б">Б</option>
                        <option value="В">В</option>
                        <option value="Г">Г</option>
                        <option value="Д">Д</option>
                      </select>
                      <input type="number" min="0" placeholder="Квартира" class="form-control mt-2" id="flat">
                    </div>
                    <button type="submit" class="btn btn-outline-dark" id="savingAccountButton" disabled>Зберегти</button>
                  </div>
                </div>
              </div>
            `

            const service = document.getElementById("exampleInputService1");
            const account = document.getElementById("exampleInputAccount1");
            const city = document.getElementById("exampleInputAddress1");
            const street = document.getElementById("street");
            const house = document.getElementById("house");
            const apartment = document.getElementById("exampleInputApartment1");
            const flat = document.getElementById("flat");
            const savingAccountButton = document.getElementById("savingAccountButton");

            function disabledSavingAccountButton() {
              if(service.value === '' || account.value.replaceAll(' ', '').length === 0 || city.value === '' || street.value.replaceAll(' ', '').length === 0 || house.value.replaceAll(' ', '').length === 0 || flat.value.replaceAll(' ', '').length === 0) {
                savingAccountButton.disabled = true;
              } else {
                savingAccountButton.disabled = false;
              }
            }

            service.addEventListener('change', disabledSavingAccountButton)
            account.addEventListener('input', disabledSavingAccountButton)
            city.addEventListener('change', disabledSavingAccountButton)
            street.addEventListener('input', disabledSavingAccountButton)
            house.addEventListener('input', disabledSavingAccountButton)
            flat.addEventListener('input', disabledSavingAccountButton)

            savingAccountButton.onclick = (e) => {
              e.preventDefault();

              document.querySelector('.modal').classList.remove('d-block');
              document.querySelector('.cs-loader').classList.remove('d-none');
              this.blockAllButtons();

              var userId = sessionStorage.getItem('userFullInfo');
              userId = JSON.parse(userId);
              userId = userId['id'];

              const serviceValue = service.value.trim();
              const accountValue = account.value.trim();
              const address_house = house.value.trim() + `${apartment.value !== '' ? apartment.value : false}`;
              const address_flat = flat.value.trim();
              const addressValue = 'смт. ' + city.value.trim() + ', вул. ' + street.value.trim() + ', буд. ' + house.value.trim() + `${apartment.value !== '' ? apartment.value : ''}` + ', кв. ' + flat.value.trim();

              return this.createPaymentFunc(userId, city.value.trim(), serviceValue, accountValue, addressValue, address_house, address_flat);
            }
          }
        }

        const logOut = document.querySelector('.logOut');

        logOut.onclick = (e) => {
          e.preventDefault();
          return this.logOutFunc();
        }
      }
    },

    async toBillingsPage() {
      this.blockAllButtons();
      this.contucts = false;
      this.pagination = false;
      document.querySelector('.cs-loader').classList.remove('d-none');
      document.querySelector('.modal').classList.remove('d-block');
      document.querySelector('.articles').innerHTML = '';
      this.editChooseButton();

      var payments;

      if(sessionStorage.getItem('accounts_page') !== null) {
        var storage = sessionStorage.getItem('accounts_page');
        storage = JSON.parse(storage);

        if(new Date().getTime() - parseInt(storage['updated_at']) > 300000) {
          payments = await this.paymentsFunc();
        } else {
          this.paymentsList = storage["list"];
          payments = true;

        }
      } else {
        payments = await this.paymentsFunc();
      }

      if(payments) {
        this.anotherPage = null;
        this.personal_accounts = true;
        sessionStorage.setItem('currentyPage', 4);
      } else {
        toastr.error('Ваших особових рахунків не знайдено!');
        this.news();
      }

      this.unBlockAllButtons();
      document.querySelector('.cs-loader').classList.add('d-none');
    },

    async createPaymentFunc(userId, city, service, account, address, house, flat) {
      try {
        const request = await this.create_payment(userId, city, service, account, address, house, flat);
        if(request.status === 200) {
          var storage = sessionStorage.getItem('accounts_page');
          if(storage !== null) {
            sessionStorage.removeItem('accounts_page');
          }

          if(sessionStorage.getItem('currentyPage') == 4) {
            toastr.options.onHidden = () => {window.location.reload()};
          }

          toastr.success(request.message)
        } else {
          toastr.error(request.message)
        }
      } catch(error) {
        toastr.error(error);
        console.error(error)
      }

      this.unBlockAllButtons();
      document.querySelector('.cs-loader').classList.add('d-none');
    },

    editChooseButton() {
      document.querySelector('.news').classList.remove('btn-warning');
      document.querySelector('.contacts').classList.remove('btn-warning');
      document.querySelector('.jobs').classList.remove('btn-warning');
    },

    hideModal() {
      document.querySelector('.modal').classList.remove('d-block');
    },

    async paymentsFunc() {
      try {
        var user = sessionStorage.getItem('userFullInfo');
        user = JSON.parse(user);
        const userId = user['id'];
        const request = await this.payments(userId);
        if(request.status === 200) {
          this.paymentsList = request.message;

          const data = {
            list: request.message,
            updated_at: new Date().getTime()
          }

          sessionStorage.setItem('accounts_page', JSON.stringify(data))

          return true;
        } else {
          return false;
        }
      } catch(error) {
        return false;
      } 
    },

    blockAllButtons() {
      const news = [...document.querySelectorAll('.news')];
      const contacts = [...document.querySelectorAll('.contacts')];
      const authModalButton = [...document.querySelectorAll('.authModalButton')];


      const disFunc = (block) => {
        block.forEach(e => e.disabled = true)
      }

      disFunc(news); disFunc(contacts); disFunc(authModalButton); 

      document.querySelectorAll('.searcherSubmit').disabled = true
      document.querySelector('.news-footer').disabled = true
      document.querySelector('.contacts-footer').disabled = true
      document.querySelector('.searcherSubmit').disabled = true
      document.querySelector('.album').style.pointerEvents = 'none';
      document.querySelector('body').style.pointerEvents = "none";
    },

    unBlockAllButtons() {
      const news = [...document.querySelectorAll('.news')];
      const contacts = [...document.querySelectorAll('.contacts')];
      const authModalButton = [...document.querySelectorAll('.authModalButton')];


      const disFunc = (block) => {
        block.forEach(e => e.disabled = false)
      }

      disFunc(news); disFunc(contacts); disFunc(authModalButton); 


      document.querySelectorAll('.searcherSubmit').disabled = false
      document.querySelector('.news-footer').disabled = false
      document.querySelector('.contacts-footer').disabled = false
      document.querySelector('.searcherSubmit').disabled = false
      document.querySelector('.album').style.pointerEvents = 'auto';
      document.querySelector('body').style.pointerEvents = "auto"
    },

    news() {
      this.pagination = false;
      this.personal_accounts = false;
      this.anotherPage = null;
      this.editChooseButton();
      this.saveArticles(1);
      document.querySelector('.news').classList.add('btn-warning');
    },

    async upload_job_list() {
      try {
        const request = await this.jobs_show();
        const status = request.status;
        const message = request.message;
        const list = request.job_list;

        if(status === "success") {
          if(list.length !== 0) {
            this.pagination = false;
            this.personal_accounts = false;
            this.editChooseButton();
            document.querySelector('.jobs').classList.add('btn-warning');
            sessionStorage.setItem('currentyPage', 2);
            localStorage.setItem("jobsStorage", JSON.stringify({created_at: new Date().getTime(), job_list: list}));

            document.querySelector('.articles').innerHTML = '';
            this.anotherPage = "JobsComponent";
            this.jobs_list = list;
          } else {
            toastr.error("На разі, вільні вакансіїї відсутні!");
            const currentyPage = sessionStorage.getItem("currentyPage");
            if(currentyPage !== null && currentyPage !== "2") {
              this.mountedFunc();
            } else {
              this.news();
            }
          }
        } else {
          toastr.error(message)
        }
      } catch (error) {
        toastr.error("Щось трапилось! Спробуйте ще раз, але пізніше!")
        console.error(error);
      }
    },

    async jobs() {
      try {
        document.querySelector('.cs-loader').classList.remove('d-none');
        this.blockAllButtons();
        this.ifUserAuth();

        const jobsStorage = localStorage.getItem("jobsStorage");

        if (jobsStorage !== null) {
          const jobsStorage_json = JSON.parse(jobsStorage);
          const created_at = jobsStorage_json.created_at;
          const currentTime = new Date().getTime();

          if (currentTime - created_at <= 1200000) {
            const job_list = jobsStorage_json.job_list;

            this.pagination = false;
            this.personal_accounts = false;
            this.editChooseButton();
            document.querySelector('.jobs').classList.add('btn-warning');
            sessionStorage.setItem('currentyPage', 2);

            document.querySelector('.articles').innerHTML = '';
            this.anotherPage = "JobsComponent";
            this.jobs_list = job_list;
          } else {
            await this.upload_job_list()
          }
        } else {
          await this.upload_job_list();
        }
      } catch (error) {
        console.error(error);
        toastr.error("Щось трапилось! Спробуйте ще раз, але пізніше!")
      }

      document.querySelector('.cs-loader').classList.add('d-none');
      this.unBlockAllButtons();

      return;
    },

    contacts() {
      this.pagination = false;
      this.personal_accounts = false; 
      this.contucts = true;
      this.editChooseButton();
      sessionStorage.setItem('currentyPage', 3);

      document.querySelector('.articles').innerHTML = '';
      this.anotherPage = "ContactComponent";

      setTimeout(() => {
        if(sessionStorage.getItem('userFullInfo') !== null) {
          var userFullInfo = JSON.parse(sessionStorage.getItem('userFullInfo'))
          var userName = userFullInfo.f;
          var userEmail = userFullInfo.e;
          var userPhone = userFullInfo.phone;

          document.querySelector("input[aria-describedby='nameHelp']").value = userName;
          document.querySelector("input[aria-describedby='emailHelp']").value = userEmail;
          userPhone !== null && userPhone !== 'null' ? document.querySelector("input[aria-describedby='phoneHelp']").value = userPhone : false;
        }

        document.querySelector('.contacts').classList.add('btn-warning');

        function isValidEmail(email) {
          const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
          return emailRegex.test(email);
        }

        function ifValidPhone(phone) {
          const phoneNumber = phone.replace(/\s/g, '');
          const phoneRegex = /^(?:\+380|380|0)\d{9,12}$/;

          return phoneRegex.test(phoneNumber);
        }

        document.querySelector('.sendMail').addEventListener('click', async (e) => {
          e.preventDefault();
          document.querySelector('.cs-loader').classList.remove('d-none');
          document.querySelector('.sendMail').disabled = true;
          this.blockAllButtons();       

          const fullname = document.querySelector('#name');
          const email = document.querySelector('#email').value; 
          const phone = document.querySelector('#phone').value; 
          const message = document.querySelector('#message');

          if (fullname.value.replace(/ /g, '').length > 0 && isValidEmail(email) && message.value.replace(/ /g, '').length > 0 && ifValidPhone(phone)) {
            try {
              const request = await this.sendMail(fullname.value, email, phone, message.value);
              if (request.status === 200) {
                toastr.success('Повідомлення відправлено! Дякуємо Вам за активність!');
              } else {
                toastr.error(request.result);
              }
            } catch (error) {
              toastr.error(error);
              console.error(error);
            }
          } else {
            var requiredFields = [];
            fullname.value.replace(/ /g, '').length === 0 ? requiredFields.push("ім'я") : false;
            message.value.replace(/ /g, '').length === 0 ? requiredFields.push('повідомлення') : false;
            !isValidEmail(email) ? requiredFields.push('пошта') : false;
            !ifValidPhone(phone) ? requiredFields.push('телефон') : false;

            requiredFields = requiredFields.join(', ');

            toastr.error(`Некоректно вказані поля: ${requiredFields}!`);
          }

          document.querySelector('.sendMail').disabled = false;
          document.querySelector('.cs-loader').classList.add('d-none');
          this.unBlockAllButtons();  
        });
      }, 1)
    },

    ifUserAuth() {
      const user = sessionStorage.getItem('user');
      const userFullInfo = sessionStorage.getItem('userFullInfo');
      const ads_href = sessionStorage.getItem('ads_href');

      if(ads_href) {
        window.open(ads_href)
        sessionStorage.removeItem("ads_href");
      }

      const modalButtons = [...document.querySelectorAll('.authModalButton')];
      if(user) {
        modalButtons.forEach(e => e.innerText = user)
      } else {
        modalButtons.forEach(e => e.innerText = 'Увійти до кабінету')
      }

      if(userFullInfo) {
        const usersDebtModal = sessionStorage.getItem('usersDebtModal');
        if(usersDebtModal === null) {
          this.debtAlert.debts = [];
          var accs = JSON.parse(userFullInfo).accounts;

          if(accs !== null && accs !== undefined) {
            accs.forEach(acc => {
              if(acc.debt < - 2000) {
                this.debtAlert.debts.push(acc);
              }
            })

            if(this.debtAlert.debts.length !== 0) {
              this.debtAlert.modal = true;
            }

            sessionStorage.setItem('usersDebtModal', true);
          }
        }
      }
    },

    async saveArticles(articleType=null) {
      const mainBlock = document.querySelector(".articles");

      document.querySelector('.cs-loader').classList.remove('d-none');
      this.blockAllButtons();
      mainBlock.innerHTML = '';
      this.ifUserAuth();

      setTimeout(async () => {
        try {
          var request;

          var articlesStorage = localStorage.getItem("articles");

          if(articlesStorage !== null) {
            articlesStorage = JSON.parse(articlesStorage);
            const time = articlesStorage.updated_at;

            if(new Date().getTime() - time < 300000) {
              request = articlesStorage.request;
            } else {
              request = articleType === null ? await this.getAllArticlesFunc() : await this.getAllArticlesFunc(articleType);
            }
          } else {
            request = articleType === null ? await this.getAllArticlesFunc() : await this.getAllArticlesFunc(articleType);
          }

          if(request.status === 200) {
            const response = request.result;
            const articlesData = {
              request: request,
              updated_at: new Date().getTime()
            }

            localStorage.setItem("articles", JSON.stringify(articlesData));

            response.forEach((e, index) => {
              const title = e.title;
              const images = e.images.split(', ');
              const image = images[0];
              const text = e.text;

              var col = document.createElement('div'); col.classList.add('col', 'article-col');
              var card = document.createElement('div'); card.classList.add('card', 'shadow-sm');

              var img_block = document.createElement('div'); img_block.classList.add('mb-5'); img_block.style.backgroundImage = `url(${this.url}/articles/image/${image})`; img_block.style.backgroundSize = 'contain'; img_block.style.backgroundPositionX = 'center'; img_block.style.backgroundPositionY = 'center'; img_block.style.backgroundRepeat = 'no-repeat'; img_block.style.height = '225px'; img_block.style.width = '100%'; 

              var card_body = document.createElement('div'); card.classList.add('card-body');
              var p = document.createElement('div'); p.classList.add('card-text'); p.innerText = title.length > 26 ? title.substring(0, 23) + ' ...' : title; p.setAttribute('title', title);
              var btn_block = document.createElement('div'); btn_block.classList.add('d-flex', 'justify-content-between', 'align-items-center');
              var btn_group = document.createElement('div'); btn_group.classList.add('btn-group');
              var btn = document.createElement('a'); btn.classList.add('btn', 'btn-sm', 'btn-outline-secondary', 'mt-1'); btn.innerText = 'Детальніше ...';
              btn.addEventListener('click', () => {
                document.querySelector('.modal').classList.add('d-block');
                document.querySelector('.modal-title').innerText = title;
                document.querySelector('.modal-body > p').innerHTML = text;
                document.querySelector('.carousel-inner').innerHTML = '';

                images.forEach(i => {
                  var img = document.createElement('img'); img.classList.add('d-block', 'mt-2', 'mb-2', 'w-100'); img.src = `${this.url}/articles/image/${i}`; img.alt = i;
                  document.querySelector('.carousel-inner').append(img)
                })
              })

              btn_group.append(btn);
              btn_block.append(btn_group);
              card_body.prepend(btn_block); card_body.prepend(p);
              card.prepend(card_body); card.prepend(img_block);
              col.append(card);

              mainBlock.append(col);

              if (index >= 2) {
                col.classList.add('d-none');
                this.articles = [...document.querySelectorAll('.article-col')]
              }
            })

            sessionStorage.setItem('currentyPage', articleType)

            if(response.length > 2) {
              this.pagination = true;
            } else {
              this.pagination = false;
            }
          } else {
            toastr.error(request.result)
          }
        } catch(error) {
          toastr.error("Упс! Перевірте з'єднання з інтернетом або спробуйте ще раз трішки пізніше!")
          console.error(error)
        }

        document.querySelector('.cs-loader').classList.add('d-none');
        return this.unBlockAllButtons();
      }, 1)
    },

    async searcher(e) {
      e.preventDefault();

      var mainBlock = document.querySelector(".articles");
      // var albomBlock = document.querySelector(".albom");
      document.querySelector('.cs-loader').classList.remove('d-none');

      this.blockAllButtons()

      setTimeout(async () => {
        if(this.searcher_text !== null && this.searcher_text.trim().length > 0) {

          var text = this.searcher_text

          try {
            var request = await this.searcherFunc(text);
            if(request.status === 200) {
              this.anotherPage = null;
              this.personal_accounts = false;
              var articles = request.result;
              if(articles.length > 0) {
                this.editChooseButton();
                document.querySelector('.news').classList.add('btn-warning');

                mainBlock.innerHTML = '';
                articles.forEach((e, index)=> {
                  var title = e.title;
                  var images = e.images.split(', ');
                  var image = images[0];
                  var text = e.text;

                  var col = document.createElement('div'); col.classList.add('col', 'article-col');
                  var card = document.createElement('div'); card.classList.add('card', 'shadow-sm');

                  var img_block = document.createElement('div'); img_block.classList.add('mb-5'); img_block.style.backgroundImage = `url(${this.url}/articles/image/${image})`; img_block.style.backgroundSize = 'contain'; img_block.style.backgroundPositionX = 'center'; img_block.style.backgroundPositionY = 'center'; img_block.style.backgroundRepeat = 'no-repeat'; img_block.style.height = '225px'; img_block.style.width = '100%'; 

                  var card_body = document.createElement('div'); card.classList.add('card-body');
                  var p = document.createElement('div'); p.classList.add('card-text'); p.innerText = title.length > 26 ? title.substring(0, 23) + ' ...' : title; p.setAttribute('title', title);
                  var btn_block = document.createElement('div'); btn_block.classList.add('d-flex', 'justify-content-between', 'align-items-center');
                  var btn_group = document.createElement('div'); btn_group.classList.add('btn-group');
                  var btn = document.createElement('a'); btn.classList.add('btn', 'btn-sm', 'btn-outline-secondary', 'mt-1'); btn.innerText = 'Детальніше ...';
                  btn.addEventListener('click', () => {
                    document.querySelector('.modal').classList.add('d-block');
                    document.querySelector('.modal-title').innerText = title;
                    document.querySelector('.modal-body > p').innerHTML = text;
                    document.querySelector('.carousel-inner').innerHTML = '';

                    images.forEach(i => {
                      var img = document.createElement('img'); img.classList.add('d-block', 'w-100', 'mt-2', 'mb-2'); img.src = `${this.url}/articles/image/${i}`; img.alt = i;
                      document.querySelector('.carousel-inner').append(img)
                    })
                  })

                  btn_group.append(btn);
                  btn_block.append(btn_group);
                  card_body.prepend(btn_block); card_body.prepend(p);
                  card.prepend(card_body); card.prepend(img_block);
                  col.append(card);

                  mainBlock.append(col);

                  if (index >= 2) {
                    col.classList.add('d-none');
                    this.articles = [...document.querySelectorAll('.article-col')]
                  }
                })

                document.querySelector(".backAllArticles").classList.remove('d-none');

                if(articles.length > 2) {
                  this.pagination = true;
                } else {
                  this.pagination = false;
                }
              } else {
                toastr.error('Інформації не знайдено!')
              }
            } else {
              toastr.error(request.result)
            }
          } catch(error) {
            toastr.error(error)
            console.error(error)
          }
        } else {
          const searchInput = document.querySelector("input[type='search']");
          searchInput.classList.add('border', 'border-danger', 'border-2');
          setTimeout(() => {
            searchInput.classList.remove('border', 'border-danger', 'border-4');
          }, 1500)
        }

        document.querySelector('.cs-loader').classList.add('d-none');
        this.unBlockAllButtons();
      }, 1000)
    },

    async backAllArticles(e) {
      e.preventDefault();

      this.searcher_text = '';
      this.pagination = false;

      await this.mountedFunc();

      document.querySelector(".backAllArticles").classList.add('d-none');
    }
  },
}
</script>

<style>
  .up-block {
    z-index: 1;
  }

  .modal.fade .modal-dialog  {
    transform: translate(0, 0px) !important;
  }

  .contuctPageCard {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .dropdown-item {
    cursor: pointer;
  }
</style>