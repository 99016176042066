<template>
  <div class="d-none d-sm-block mx-1 h-100 position-fixed leftPanel z-3">
    <div>
      <div class="my-1">
        <img class="calculator" width="54" height="54" :src="calculator.image" @click="calculatorModal" title="Калькулятор" />
      </div>
      <div class="my-2">
        <img class="orders" width="55" height="55" :src="ordersWidget.image" @click="orderWidgetShowModal()" title="Інформація щодо зікупівель" />
      </div>
      <div class="my-2">
        <img class="purchase" width="57" height="52" :src="logo" @click="orders" title="Закупівлі" />
      </div>
      <div class="my-2">
        <img class="purchase" width="54" height="54" :src="chat.image" @click="openChat" title="Онлайн чат" />
      </div>
    </div>
  </div>

  <div v-if="ordersWidget.modal === true">
    <OrdersModal :ordersWidget="ordersWidget" />
  </div>

  <div v-if="chat.modal === true">
    <ChatModal :chat="chat" />
  </div>   

  <div v-if="calculator.modal" class="modal d-block" tabindex="-1">
    <CalculatorModal :calculator="calculator" />
  </div>
</template>

<script>
import logo from '@/assets/logo_3.png';
import OrdersModal from '@/components/Modals/OrdersModal.vue';
import ChatModal from '@/components/Modals/ChatModal.vue';
import CalculatorModal from '@/components/Modals/CalculatorModal.vue';
import calculator from '@/assets/calculator.png';
import ordersImage from '@/assets/orders.png';
import chatImage from '@/assets/chat.png';

export default {
  components: { OrdersModal, ChatModal, CalculatorModal },
  data() {
    return {
      logo: logo,
      chat: {
        image: chatImage,
        modal: false,
      },
      ordersWidget: {
        image: ordersImage,
        modal: false
      },
      calculator: {
        image: calculator,
        modal: false
      }
    };
  },
  methods: {
    openChat() {
      this.chat.modal = true;
      this.ordersWidget.modal = false;
      this.calculator.modal = false;
      const body = document.body;
      const modal = document.querySelector('#modal_prom_state_purchase_block');
      modal.style.display = 'none';
      body.style.overflow = "auto";
    },

    ordersModal() {
      const body = document.body;
      const modal = document.querySelector('#modal_prom_state_purchase_block');
      if(modal.style.display === 'block') {
        modal.style.display = 'none';
        body.style.overflow = "auto";
      } else {
        modal.style.display = 'block';
        body.style.overflow = "hidden";
      }
    },

    orders() {
      this.ordersWidget.modal = false;
      this.calculator.modal = false;
      this.chat.modal = false;
      this.ordersModal();
    },

    orderWidgetShowModal() {
      this.ordersWidget.modal = true;
      this.calculator.modal = false;
      this.chat.modal = false;
      const modal = document.querySelector('#modal_prom_state_purchase_block');
      modal.style.display = 'none';
      const body = document.body;
      body.style.overflow = "auto";
    },

    calculatorModal() {
      const body = document.body;
      const modal = document.querySelector('#modal_prom_state_purchase_block');
      modal.style.display = 'none';
      body.style.overflow = "auto";

      this.ordersWidget.modal = false;
      this.chat.modal = false;
      this.calculator.modal = true;
    }
  },
}
</script>
