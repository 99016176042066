import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import routers from './routers.js';
import CKEditor from '@ckeditor/ckeditor5-vue';
import store from '@/stores/index.js';
import './registerServiceWorker';

const app = createApp(App);
app.use(CKEditor);
app.use(store);
app.use(routers);
app.mount('#app');
