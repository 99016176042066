<template>
  <div class="d-flex align-items-center justify-content-center w-100" style='height: 100vh;'>
    <!-- nothing -->
  </div>
</template>

<script>
export default {
    name: "SessionView",
    props: {
      data: Object
    },
    mounted() {
      const data = JSON.parse(this.data);
      if(data.status === 200) {
        var ads_show = data.ads_show;
        var ads_href = data.ads_href;

        if(ads_show !== false) {
          sessionStorage.setItem('ads_href', ads_href);
        }

        sessionStorage.setItem('userFullInfo', JSON.stringify(data.user))
        sessionStorage.setItem('user', data.user.f)
      } else {
        sessionStorage.setItem('alert', data.user)
      }

      this.$router.push({name: 'welcome'});
    }
}
</script>