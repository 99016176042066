<template>
  <div class="modal d-block" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">Інформація щодо зікупівель</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="ordersWidget.modal = false"></button>
        </div>
        <div class="modal-body">
          <KeepAlive>
            <component :is="current_component" :orders="orders" :xlsx="xlsx" :docx="docx" :url="url" />
          </KeepAlive>

          <hr />

          <div class="switcher-block d-flex mt-3 align-items-center justify-content-center">
            <label class="form-check-label" for="flexSwitchCheckChecked">Обгрунтування закупівель</label>
            <div class="form-check form-switch mx-3 d-flex justify-content-center">
              <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="switcher.checked" @change="edit_orders">
            </div>
            <label class="form-check-label" for="flexSwitchCheckChecked">Оголошення закупівель</label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="ordersWidget.modal = false">Закрити</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toastr from 'toastr';
import 'toastr/toastr.scss';
import xlsx from '@/assets/xlsx.png';
import docx from '@/assets/docx.png';
import showOrdersFunc from '@/mixins/api.js';
import showAnnouncementOrdersFunc from '@/mixins/api.js';
import PuchaseRationale from '@/components/Layouts/PuchaseRationale.vue';
import PuchaseAnnouncement from '@/components/Layouts/PuchaseAnnouncement.vue';

export default {
    name: "OrdersModal",
    mixins: [showOrdersFunc, showAnnouncementOrdersFunc],
    components: { PuchaseRationale, PuchaseAnnouncement },
    props: {
      ordersWidget: Object
    },
    created() {
      toastr.options = {
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-top-center',
        timeOut: 1300,
        toastClass: 'custom-toast',
      }

      this.showOrders();
    },
    data() {
      return {
        current_component: 'PuchaseRationale',
        switcher: {
          checked: false,
        },
        url: this.$store.getters.getUrl,
        orders: null,
        xlsx: xlsx,
        docx: docx
      }
    },
    methods: {
      async edit_orders() {
        const switcher = this.switcher;
        const current_component = this.current_component;

        if (current_component == 'PuchaseRationale') {
          const show_orders = await this.showOrders(true);

          if (show_orders.status === 'success') {
            this.current_component = 'PuchaseAnnouncement';
          } else {
            switcher.checked = false;
          }
          
        } else {
          const show_orders = await this.showOrders(false);

          if (show_orders.status === 'success') {
            this.current_component = 'PuchaseRationale';
          } else {
            switcher.checked = true;
          }
        }

        return;
      },

      blockAllButtons() {
        const news = [...document.querySelectorAll('.news')];
        const contacts = [...document.querySelectorAll('.contacts')];
        const authModalButton = [...document.querySelectorAll('.authModalButton')];


        const disFunc = (block) => {
          block.forEach(e => e.disabled = true)
        }

        disFunc(news); disFunc(contacts); disFunc(authModalButton); 

        document.querySelectorAll('.searcherSubmit').disabled = true
        document.querySelector('.news-footer').disabled = true
        document.querySelector('.contacts-footer').disabled = true
        document.querySelector('.searcherSubmit').disabled = true
        document.querySelector('.album').style.pointerEvents = 'none';

        document.querySelector('.cs-loader').classList.remove('d-none');
      },

      unBlockAllButtons() {
        const news = [...document.querySelectorAll('.news')];
        const contacts = [...document.querySelectorAll('.contacts')];
        const authModalButton = [...document.querySelectorAll('.authModalButton')];


        const disFunc = (block) => {
          block.forEach(e => e.disabled = false)
        }

        disFunc(news); disFunc(contacts); disFunc(authModalButton); 


        document.querySelectorAll('.searcherSubmit').disabled = false
        document.querySelector('.news-footer').disabled = false
        document.querySelector('.contacts-footer').disabled = false
        document.querySelector('.searcherSubmit').disabled = false
        document.querySelector('.album').style.pointerEvents = 'auto';

        document.querySelector('.cs-loader').classList.add('d-none');
      },

      async showOrders(announcement=false) {
        var response_json = {status: 'success'}
        this.blockAllButtons();

        try {
          var message;

          if (!announcement) {
            const request = await this.showOrdersFunc();
            const status = request.status;
            message = request.message;

            if(status === 200) {
              this.unBlockAllButtons();
              this.orders = message;
            } else {
              response_json.status = 'error';
              toastr.error(message);
            }

          } else {
            const request = await this.showAnnouncementOrdersFunc();
            const status = request.status;
            message = request.err_description;
            const files = request.files;

            if(status === 'success') {
              this.unBlockAllButtons();
              const update_file_names = files.map(file => {
                const updatedFile = { ...file };
                updatedFile.filename = file.filename.split('zhylservice_orders/')[1];
                return updatedFile;
              });

              this.orders = update_file_names;
            } else {
              response_json.status = 'error';
              toastr.error(message);
            }
          }
        } catch (error) {
          console.error(error);
          toastr.error(error.message);
        }

        this.unBlockAllButtons();
        return response_json;
      }
    }
}
</script>