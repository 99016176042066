<template>
  <div class="AdminPage">
    <div>
      <BackSwitcherButtonComponent />
      <UpButtonComponent />

      <div class="px-4 py-5 pb-1 border-bottom text-center bg-gradient text-white title-block">
        <img class="d-block mx-auto mb-4 border border-white border-2 rounded p-1 shadow-lg" src="@/assets/logo.jpg" alt="" width="100" height="100">
        <h1 class="display-6 fw-bold">Комунальне підприємство</h1>
        <h3 class="display-7 fw-bold">"ЖИЛСЕРВІС"</h3>
        <p class="mb-0">Черкаської</p>
        <p class="mb-0">селищної ради</p>
        <header class="p-3 mt-5">
          <div class="container">
            <hr class="shadow" />
            <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
              <ul class="nav d-none d-sm-flex col-12 me-lg-auto justify-content-center mb-md-0">
                <li><a class="btn btn-outline-light m-2 createPage hover" @click='createPage'>Створити статтю</a></li>
                <li><a class="btn btn-outline-light m-2 updatePage" @click='updatePage'>Редагувати статтю</a></li>
                <li><a class="btn btn-outline-light m-2 deletePage" @click='deletePage'>Видалити статтю</a></li>
                <li><a class="btn btn-outline-light m-2 addJob" @click='addJob'>Додати вакансію</a></li>
                <li><a class="btn btn-outline-light m-2 mailPage" @click='mailPage'>Перевірити пошту</a></li>
                <li><a class="btn btn-outline-light m-2 usersPage" @click='usersPage'>Переглянути користувачів</a></li>
                <li><a class="btn btn-outline-light m-2 debtorsPage" @click='debtorsPage'>Переглянути боржників</a></li>
                <li><a class="btn btn-outline-light m-2 importExcel" @click='importExcelPage'>Імпортувати дані по рахункам</a></li>
                <li><a class="btn btn-outline-light m-2 importOrder" @click='importOrderPage'>Імпортувати закупівлю</a></li>

                <li><a class="btn btn-warning m-2 border border-white text-white" @click='toMainPage'>Назад до головної</a></li>
              </ul>

              <div class="burger d-flex d-sm-none flex-wrap w-100 align-items-center justify-content-center">
                <button class="btn btn-success border border-1 border-white btn-lg w-25 burger-btn text-white" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  ≡
                </button>
                <ul class="dropdown-menu dropdown-menu-light text-center">
                  <li class=""><span class="dropdown-item createPage" @click='createPage'>Створити статтю</span></li>
                  <li class=""><span class="dropdown-item updatePage" @click='updatePage'>Редагувати статтю</span></li>
                  <li class=""><span class="dropdown-item deletePage" @click='deletePage'>Видалити статтю</span></li>
                  <li class=""><span class="dropdown-item addJob" @click='addJob'>Додати вакансію</span></li>
                  <li class=""><span class="dropdown-item mailPage" @click='mailPage'>Перевірити пошту</span></li>
                  <li class=""><span class="dropdown-item usersPage" @click='usersPage'>Переглянути користувачів</span></li>
                  <li class=""><span class="dropdown-item debtorsPage" @click='debtorsPage'>Переглянути боржників</span></li>
                  <li class=""><span class="dropdown-item importExcel" @click='importExcelPage'>Імпортувати дані по рахункам</span></li>
                  <li class=""><span class="dropdown-item importOrder" @click='importOrderPage'>Імпортувати закупівлю</span></li>

                  <li class=""><span class="dropdown-item fw-bold" @click='toMainPage'>Назад до головної</span></li>
                </ul>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div class="container mb-4">
        <div class="row create_article mt-3 mb-3" v-if="page === 'create'">
          <div class="">
            <div class="row">
              <div class="col-md-4 mb-3">
                <label for="articleTitle">Назва статті <span class='text-danger'>*</span></label>
                <input type="text" class="form-control" id="articleTitle" placeholder="" required="" v-model="article.title">
              </div>
            </div>
            <div class="row">
              <div class="mb-3 col-md-4">
                <label for="articleImage">Зображення (jpg, png) <span class='text-danger'>*</span></label>
                <div class="input-group">
                  <input type="file" class="form-control" id="articleImage" multiple>
                </div>
              </div>
            </div>
            <div class="mb-3 col-md-2">
              <label for="articleType">Тип <span class='text-danger'>*</span></label>
              <div class="input-group">
                <select class="form-select" id="articleType" v-model='article.type'>
                  <option value='1'>Новина</option>
                </select>
              </div>
            </div>
            <div class="row ml-0">
              <div class="mb-3 w-100">
                <label for="articleText">Текст статті <span class='text-danger'>*</span></label>
                <!-- <textarea id="articleText" required="" class="d-block form-control" rows="10" v-model="article.text"></textarea> -->
                <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
              </div>
            </div>
            <button class="btn btn-warning btn-lg btn-block" @click="createArticle" type="submit">Опублікувати</button>
          </div>
        </div>

        <div class="row job_article mb-3" v-if="page === 'addJob'">
          <div class="my-4">
           <table ref="myTable" class="table table-striped table-bordered text-center border table-light table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Назва вакансії</th>
                <th scope="col">Опис вакансії</th>
                <th scope="col">Дата розміщення</th>
                <th scope="col">Дії</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for='item, index in job_list' :key='index'>
                <th scope="row mt-1">{{ index + 1 }}</th>
                <td>{{ item.title }}</td>
                <td v-html="item.description"></td>
                <td>{{ item.date }}</td>
                <td>
                  <a class="text-decoration-none" href="" @click.prevent="update_job(index)">📝</a>
                  /
                  <a class="text-decoration-none" href="" @click.prevent="delete_job(index)">🗑</a>
                </td>
              </tr>
            </tbody>
           </table>
          </div>

          <div class="">
            <div class="row d-none">
              <div class="col-md-4 mb-3">
                <label for="jobID">ID вакансії <span class='text-danger'>*</span></label>
                <input type="text" class="form-control" id="jobID" placeholder="" required="" v-model="job.id">
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 mb-3">
                <label for="jobTitle">Назва вакансії <span class='text-danger'>*</span></label>
                <input type="text" class="form-control" id="jobTitle" placeholder="" required="" v-model="job.title">
              </div>
            </div>
            <div class="row ml-0">
              <div class="mb-3 w-100">
                <label for="jobDescription">Опис вакансії <span class='text-danger'>*</span></label>
                <!-- <textarea id="jobDescription" required="" class="d-block form-control" rows="10" v-model="job.description"></textarea> -->
                <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
              </div>
            </div>
            <button class="btn btn-warning btn-lg btn-block" @click="addNewJob" type="submit">Опублікувати</button>
          </div>
        </div>

        <div class="row update_article mb-3" v-if="page === 'update'">
          <div class='mt-4 mb-4' if='article.articles !== null'>
           <table ref="myTable" class="table table-striped table-bordered border text-center table-light table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Назва статті</th>
                <th scope="col">Дії</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for='art in article.articles' :key='art.id'>
                <th scope="row mt-1">{{art.id}}</th>
                <td class='mt-1' :title="art.title">{{art.title.length > 30 ? art.title.substring(0, 27) + '...' : art.title}}</td>
                <td>
                  <a class='link-offset-2 link-underline link-underline-opacity-0' title='ВСТАВИТИ В ПОШУК' @click.prevent='insertToInput(art.title)'>
                    📝
                  </a>
                </td>
              </tr>
            </tbody>
           </table>
          </div>

          <div class="">
            <div class="row">
              <div class="mb-3 col-md-4">
                <label for="title">Назва статті <span class='text-danger'>*</span></label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">@</span>
                  </div>
                  <input type="text" class="form-control" id="title" v-model='article.find'>
                </div>
              </div>
            </div>
            <button class="btn btn-warning btn-lg btn-block" @click="findArticle(null)" type="submit">Знайти</button>
          </div>

          <div class=" mt-4 founderArticle" v-if='article.search === true'>
            <div class="row">
              <div class="col-md-4 mb-3">
                <label for="articleTitle">Назва статті <span class='text-danger'>*</span></label>
                <input type="text" class="form-control" id="articleTitle" placeholder="" required="" v-model="article.title">
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label for="articleImage">Зображення (jpg, png)</label>
                <div class="input-group">
                  <input type="file" class="form-control" id="articleImage" multiple>
                </div>
              </div>
              <div class="imagesSet mt-0 mb-2 d-flex row row-cols-3 row-cols-lg-6">

              </div>
            </div>
            <div class="mb-3 col-md-2">
              <label for="articleType">Тип <span class='text-danger'>*</span></label>
              <div class="input-group">
                <select class="form-select" id="articleType" v-model='article.type'>
                  <option value='1'>Новина</option>
                </select>
              </div>
            </div>
            <div class="row ml-0">
              <div class="mb-3 w-100">
                <label for="articleText">Текст статті <span class='text-danger'>*</span></label>
                <!-- <textarea id="articleText" required="" class="d-block form-control" rows="10" v-model="article.text"></textarea> -->
                <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
              </div>
            </div>
            <button class="btn btn-primary btn-lg btn-block" @click="updateArticle" type="submit">Зберегти</button>
          </div>
        </div>

        <div class="row delete_article" v-if="page === 'delete'">
          <div class='mt-4 mb-4' if='article.articles !== null'>
           <table ref="myTable" class="table table-striped table-bordered text-center border table-light table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Назва статті</th>
                <th scope="col">Дії</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for='art in article.articles' :key='art.id'>
                <th scope="row mt-1">{{art.id}}</th>
                <td class='mt-1' :title="art.title">{{art.title.length > 30 ? art.title.substring(0, 27) + '...' : art.title}}</td>
                <td>
                  <a class='link-offset-2 link-underline link-underline-opacity-0' title='ВСТАВИТИ В ПОШУК' @click.prevent='insertToInput(art.title)'>
                    📝
                  </a>
                </td>
              </tr>
            </tbody>
           </table>
          </div>

          <div class="">
            <div class="row">
              <div class="mb-3">
                <label for="title">Назва статті <span class='text-danger'>*</span></label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">@</span>
                  </div>
                  <input type="text" class="form-control" id="title" v-model='article.del'>
                </div>
              </div>
            </div>
            <button class="btn btn-danger btn-lg btn-block" @click="deleteArticle" type="submit">Видалити</button>
            <button class="btn btn-outline-danger btn-lg btn-block m-3" @click="deleteAllArticles" type="submit">Видалити все</button>
          </div>
        </div>

        <div class="row users mb-3" v-if="page === 'users'">
          <div class='mt-4 mb-4' if='users.users !== null'>
           <div class="table-responsive">
             <table ref="myTable" class="table table-striped table-bordered border text-center table-light table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Аватар</th>
                  <th scope="col">Ім'я</th>
                  <th scope="col">Пошта</th>
                  <th scope="col">Телефон</th>
                  <th scope="col">Дії</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for='user in users.users' :key='user.id'>
                  <td class='mt-1' :title="user.id">{{user.id}}</td>
                  <td class='mt-1'><img :src="user.avatar" width="50" /></td>
                  <td class='mt-1' :title="user.f">{{user.f}}</td>
                  <td class='mt-1' :title="user.e">{{user.e}}</td>
                  <td class='mt-1' :title="user.phone !== 'null' ? user.phone : false">{{user.phone !== 'null' ? user.phone : false}}</td>
                  <td>
                    <a class='link-offset-2 link-underline link-underline-opacity-0' title='ПЕРЕГЛЯНУТИ ДАНІ КОРИСТУВАЧА' @click.prevent='showData(user)'>
                      📝
                    </a>
                  </td>
                </tr>
              </tbody>
             </table>
           </div>
          </div>
        </div>

        <div class="row debtors mb-3" v-if="page === 'debtors'">
          <div class="table-responsive mt-4 mb-4">
             <table ref="myTable" class="table table-striped table-bordered border text-center table-light table-hover">
              <thead>
                <tr>
                  <th scope="col">Ім'я</th>
                  <th scope="col">Пошта</th>
                  <th scope="col">Телефон</th>
                  <th scope="col">Рахунок</th>
                  <th scope="col">Борг</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for='debtor in debtors' :key='debtor.id'>
                  <td class='mt-1' :title="debtor.f">{{debtor.f}}</td>
                  <td class='mt-1' :title="debtor.e">{{debtor.e}}</td>
                  <td class='mt-1' :title="debtor.phone">{{debtor.phone}}</td>
                  <td class='mt-1' :title="debtor.account">{{debtor.account}}</td>
                  <td class='mt-1' :title="debtor.all_sum">{{debtor.all_sum}}</td>
                </tr>
              </tbody>
             </table>
           </div>

           <div class="w-100 d-flex align-items-center justify-content-center mb-2">
            <button class="btn btn-danger btn-lg" @click="debtorsMail">Розіслати повідомлення про борг</button>
           </div>
        </div>

        <div class="row update_article mb-3" v-if="page === 'mail'">
          <div class='mt-4 mb-4' if='mail.messages !== null'>
          <div class="table-responsive">
             <table ref="myTable" class="table table-striped table-bordered border text-center table-light table-hover">
              <thead>
                <tr>
                  <th scope="col">Ім'я відправника</th>
                  <th scope="col">Пошта відправника</th>
                  <th scope="col">Телефон відправника</th>
                  <th scope="col">Дата відправлення</th>
                  <th scope="col">Дії</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for='message in mail.messages' :key='message.id'>
                  <td class='mt-1' :title="message.name">{{message.name}}</td>
                  <td class='mt-1' :title="message.email">{{message.email}}</td>
                  <td class='mt-1' :title="message.phone !== 'null' ? message.phone : false">{{message.phone !== 'null' ? message.phone : false}}</td>
                  <td class='mt-1' :title="message.created_at">{{message.created_at}}</td>
                  <td>
                    <a class='link-offset-2 link-underline link-underline-opacity-0' title='ПЕРЕГЛЯНУТИ ПОВІДОМЛЕННЯ ТА ВІДПОВІСТИ' @click.prevent='showMessage(message)'>
                      📝
                    </a>
                    <a class='link-offset-2 link-underline link-underline-opacity-0' title='ВИДАЛИТИ ПОВІДОМЛЕННЯ' @click.prevent='deleteMessage(message.id)'>
                      🗑
                    </a>
                  </td>
                </tr>
              </tbody>
             </table>
           </div>
          </div>
        </div>

        <div class="row import mt-3 mb-3" v-if="page === 'import'">
          <div class="">
            <div class="row">
              <div class="mb-3 col-md-4">
                <label for="importFile">Файл (xls, xlsx) <span class='text-danger'>*</span></label>
                <div class="input-group">
                  <input type="file" class="form-control" id="importFile" multiple>
                </div>
              </div>
            </div>
            <div class="mb-3 col-md-2">
              <label for="city">Смт. <span class='text-danger'>*</span></label>
              <div class="input-group">
                <select class="form-select" id="city" v-model='import_data.city'>
                  <option value='Гвардійське' selected>Гвардійське</option>
                  <option value='Черкаське'>Черкаське</option>
                </select>
              </div>
            </div>
            <button class="btn btn-warning btn-lg btn-block" @click="importExcel" type="submit">Імпортувати</button>
          </div>
        </div>

        <div class="row orders mb-3" v-if="page === 'orders'">
          <div class='mt-4 mb-4'>
            <div class="table-responsive">
              <table ref="myTable" class="table table-striped table-bordered border text-center table-light table-hover">
                <thead>
                  <tr>
                    <th scope="col">Закупівля</th>
                    <th scope="col">Дії</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for='item in orders' :key='item.id'>
                    <td class='mt-1' :title="item.filename">
                      <a :href="`${url}/${item.href}`" title="Завантажити обгрунтування" tagret="_blank">
                        {{item.filename.length > 25 ? item.filename.substr(0, 22) + '...' : item.filename}}
                      </a>
                    </td>
                    <td>
                      <a class='link-offset-2 link-underline link-underline-opacity-0' title='ВИДАЛИТИ ЗАКУПІВЛЮ' @click.prevent='deleteOrder(item.id)'>
                        🗑
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> 

          <div class='mt-2 mb-4'>
            <div class="table-responsive">
              <table ref="mySecondTable" class="second-table table table-striped table-bordered border text-center table-light table-hover">
                <thead>
                  <tr>
                    <th scope="col">Закупівля</th>
                    <th scope="col">Дії</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for='item in announcementOrders' :key='item.id'>
                    <td class='mt-1' :title="item.filename">
                      <a :href="item.href" title="Завантажити обгрунтування" tagret="_blank">
                        {{item.filename.length > 25 ? item.filename.substr(0, 22) + '...' : item.filename}}
                      </a>
                    </td>
                    <td>
                      <a class='link-offset-2 link-underline link-underline-opacity-0' title='ВИДАЛИТИ ЗАКУПІВЛЮ' @click.prevent='deleteAnnouncementOrder(item.filename)'>
                        🗑
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> 

          <div class="forms d-flex flex-column flex-md-row gap-4  align-items-center justify-content-center">
            <div class="d-block position-static p-4 mx-0 rounded-3 shadow overflow-hidden w-400px">
              <div class="mb-3">
                <label for="puchaseRationale" class="mb-2">Обгрунтування закупівлі (xls, xlsx) <span class='text-danger'>*</span></label>
                <div class="input-group">
                  <input type="file" class="form-control" id="puchaseRationale">
                </div>
              </div>
              <button class="btn btn-warning btn-block" @click="importOrder" type="submit">Імпортувати</button>
            </div>
            <div class="d-block position-static p-4 mx-0 rounded-3 shadow overflow-hidden w-400px">
              <div class="mb-3">
                <label for="puchaseAnnouncement" class="mb-2">Оголошення закупівлі (docx) <span class='text-danger'>*</span></label>
                <div class="input-group">
                  <input type="file" class="form-control" id="puchaseAnnouncement">
                </div>
              </div>
              <button class="btn btn-warning btn-block" @click="importAnnouncementOrder" type="submit">Імпортувати</button>
            </div>
          </div>
        </div>
      </div>

      <div class="cs-loader d-none fixed-bottom">
        <div class="cs-loader-inner">
          <label>●</label>
          <label>●</label>
          <label>●</label>
          <label>●</label>
          <label>●</label>
        </div>
      </div>

      <div v-if="mail.modal">
        <MailsModal :mail="mail" :disabledMenu="disabledMenu" :unDisabledMenu="unDisabledMenu" :currentyMessage="currentyMessage" />
      </div>

      <div v-if="users.modal">
        <UsersModal :users="users" />
      </div>
    </div>
  </div>
</template>

<script>
import UpButtonComponent from './UpButtonComponent.vue';
import BackSwitcherButtonComponent from './BackSwitcherButtonComponent.vue';
import UsersModal from './Modals/UsersModal.vue';
import MailsModal from './Modals/MailsModal.vue';
import toastr from 'toastr';
import 'toastr/toastr.scss';
import '@/assets/styles/spinner.css'
import importExcelFunc from '@/mixins/api.js';
import createArticleFunc from '@/mixins/api.js';
import updateArticleFunc from '@/mixins/api.js';
import deleteArticleFunc from '@/mixins/api.js';
import getArticleFunc from '@/mixins/api.js';
import getAllArticlesFunc from '@/mixins/api.js';
import deleteAllArticlesFunc from '@/mixins/api.js';
import deleteArticleImage from '@/mixins/api.js';
import showMailsFunc from '@/mixins/api.js';
import showUsersFunc from '@/mixins/api.js';
import deleteMessageFunc from '@/mixins/api.js';
import selectAccountsFunc from '@/mixins/api.js';
import showOrdersFunc from '@/mixins/api.js';
import uploadOrderFunc from '@/mixins/api.js';
import deleteOrderFunc from '@/mixins/api.js';
import all_debtors from '@/mixins/api.js';
import debtors_mail from '@/mixins/api.js';
import debtors_phone_mail from '@/mixins/api.js';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from 'jquery';  
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import add_new_job from "@/mixins/api.js";
import jobs_show from "@/mixins/api.js";
import delete_job_execute from "@/mixins/api.js";
import uploadAnnouncementOrderFunc from "@/mixins/api.js";
import showAnnouncementOrdersFunc from '@/mixins/api.js';
import deleteAnnouncementOrderFunc from '@/mixins/api.js';

export default {
  name: 'AdminPage',
  components: {
    UpButtonComponent,
    BackSwitcherButtonComponent,
    UsersModal,
    MailsModal
  },
  mixins: [delete_job_execute, jobs_show, createArticleFunc, updateArticleFunc, deleteArticleFunc, getArticleFunc, deleteAllArticlesFunc, getAllArticlesFunc, importExcelFunc, deleteArticleImage, deleteMessageFunc, showMailsFunc, showUsersFunc, selectAccountsFunc, showOrdersFunc, deleteAnnouncementOrderFunc, showAnnouncementOrdersFunc, uploadOrderFunc, deleteOrderFunc, all_debtors, debtors_mail, debtors_phone_mail, add_new_job],
  data() {
    return {
      job_list: [],
      job: {
        id: null,
        title: "",
        description: ""
      },
      debtors: [],
      url: this.$store.getters.getUrl,
      orders: [],
      announcementOrders: [],
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {},
      currentYear: new Date().getFullYear(),
      url: this.$store.getters.getUrl,
      page: 'create',
      article: {
        title: null,
        text: null,
        link: null,
        find: null,
        type: null,
        del: null,
        articles: null,
        search: false
      },
      import_data: {
        city: 'Гвардійське'
      },
      mail: {
        messages: [{id: 1, name: "ЖИЛСЕРВІС", email: "zhylservise@gmail.com", "message": "Вітаю, працівник ЖИЛСЕРВІСу!"}],
        modal: false,
        currentyMessage: null,
        answer: ''
      },
      users: {
        users: [{id: 1, f: "ЖИЛСЕРВІС", e: "zhylservise@gmail.com"}],
        modal: false,
        currentUser: {
          id: 1, 
          f: "ЖИЛСЕРВІС", 
          e: "zhylservise@gmail.com"
        },
        currentAccounts: [],
        accountsId: []
      }
    }
  },

  created() {
    this.auth();
    this.clearArticleOptions();
  },

  mounted() {    
    this.theme();
    toastr.options = {
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-top-center',
      timeOut: 1600,
      toastClass: 'custom-toast',
    }
  },

  updated() {
    this.pagination();
  },

  methods: {
    async usersFunc() {
      var usersStorage = sessionStorage.getItem('usersStorage');
      var insertToStorage = true;
      var request;
      var data = {
        request: null,
        created_at: new Date().getTime()
      };

      function insertToStorageFunc(request) {
        data.request = request;
        data = JSON.stringify(data);
        sessionStorage.setItem('usersStorage', data);
      }

      try {
        if(usersStorage !== null) {
          usersStorage = JSON.parse(usersStorage);

          if(new Date().getTime() - usersStorage['created_at'] <= 300000) {
            insertToStorage = false;
            request = usersStorage['request'];
          } else {
            request = await this.showUsersFunc();
          }
        } else {
          request = await this.showUsersFunc();
        }

        if(request['status'] === 200) {
          this.users.users = request['message'];
          insertToStorage === true ? insertToStorageFunc(request) : false;
        } else {
          toastr.error(request['message'])
        }
      } catch(error) {
        console.error(error)
        toastr.error('Користувачі відсутні!')
      }
    },

    theme() {
      const titleBlock = document.querySelector('.title-block');
      const upBlock = document.querySelector('.up-button');
      const burger = document.querySelector('.burger-btn');
      const authModalButton = document.querySelector('#authModalButton');

      const needEdit = [titleBlock, upBlock, authModalButton, burger];

      const color = localStorage.getItem('theme');
      var bg = 'bg-success';

      if(color !== null) {
        if(color == 2) {
          bg = 'bg-secondary';
        } else if(color == 3) {
          bg = 'bg-dark';
        }
      }

      needEdit.forEach(e => {
        if(e !== null) {
          e.classList.add(bg);
        }
      })
    },

    async showMails() {
      try {
        const request = await this.showMailsFunc();
        if(request['status'] === 200) {
          this.mail.messages = request['message']
        } else {
          toastr.error(request['message'])
        }
      } catch(error) {
        console.error(error)
        toastr.error('Повідомлення відсутні!')
      }
    },

    showMessage(message) {
      this.currentyMessage = message;
      this.mail.modal = true;
    },

    async selectAccounts(id) {
      this.disabledMenu();

      try {
        const request = await this.selectAccountsFunc(id);
        if(request.status === 200) {
          this.users.currentAccounts = request.message

          const accountsId = [];

          request.message.forEach(e => {
            var ifAccountExists = accountsId.some(acc => acc['account'] === e['account'])
            if(ifAccountExists === false) {
              accountsId.push({account: e['account'], city: e['city']})
            }
          })

          this.users.accountsId = [...new Set(accountsId)];

          this.unDisabledMenu();
          return true
        } else {
          toastr.error(request.message)
        }

        this.unDisabledMenu();
        return false
      } catch(error) {
        console.error(error)
        toastr.error("Особових рахунків не знайдено!");
        this.unDisabledMenu();
        return false
      }
    },

    async showData(user) {
      const selectAccounts = await this.selectAccounts(user.id);
      if(selectAccounts) {
        this.users.currentUser = user;
        this.users.modal = true;
      }
    },

    async deleteMessage(id) {
      this.disabledMenu();

      try {
        const request = await this.deleteMessageFunc(id);
        if(request.status === 200) {
          toastr.success(request.message);
          this.mailPage();
        }  else {
          toastr.error(request.message)
        }
      } catch(error) {
        console.error(error)
        toastr.error(error)
      }

      this.unDisabledMenu();
    },

    async deleteOrder(id) {
      this.disabledMenu();

      try {
        const request = await this.deleteOrderFunc(id);
        toastr.info(request.message);

        if(request.status === 200) {
          sessionStorage.removeItem('orders');
          localStorage.removeItem('orders');
          this.orders = [];
          this.importOrderPage();
        }
      } catch(error) {
        console.error(error)
        toastr.error('Упс... Щось трапилось! Спробуйте ще раз!')
      }

      this.unDisabledMenu();
    },

    async deleteAnnouncementOrder(title) {
      this.disabledMenu();

      try {
        const request = await this.deleteAnnouncementOrderFunc(title);
        const status = request.status;
        const response = request.response;
        toastr.info(response);

        if(status === 'success') {
          this.announcementOrders = [];
          this.importOrderPage();
        }
      } catch(error) {
        console.error(error)
        toastr.error('Упс... Щось трапилось! Спробуйте ще раз!')
      }

      this.unDisabledMenu();
    },

    async debtorsMail() {
      this.disabledMenu();

      try {
        const debtors = this.debtors;

        debtors.forEach(async debtor => {
          if(debtor.phone !== null) {
            try {
              var request = await this.debtors_phone_mail(debtor.f, debtor.e, debtor.phone, debtor.account, debtor.all_sum);
              console.log(request.message)
            } catch (error) {
              console.error(error)
            }
          }
        })


        var request = await this.debtors_mail(debtors);
        console.log(request.message)
      } catch (error) {
        console.error(error)
      }

      toastr.info('Повідомлення розіслані!');
      return this.unDisabledMenu();
    },

    pagination() {
      if($('.table')) {
        $(this.$refs.myTable).DataTable().destroy();
        $(this.$refs.myTable).DataTable({
          "paging": true,
          "lengthChange": false,
          "pageLength": 10,
          "searching": true,
          "ordering": true,
          "info": false,
          "autoWidth": false,
          "language": {
            "search": "Пошук:",
            "zeroRecords": "Співпадінь не знайдено",
            "paginate": {
              "first": "Перша",
              "last": "Остання",
              "next": "Наступна",
              "previous": "Попередня"
            },
          }
        });
      }

      if($('.second-table')) {
        $(this.$refs.mySecondTable).DataTable().destroy();
        $(this.$refs.mySecondTable).DataTable({
          "paging": true,
          "lengthChange": false,
          "pageLength": 10,
          "searching": true,
          "ordering": true,
          "info": false,
          "autoWidth": false,
          "language": {
            "search": "Пошук:",
            "zeroRecords": "Співпадінь не знайдено",
            "paginate": {
              "first": "Перша",
              "last": "Остання",
              "next": "Наступна",
              "previous": "Попередня"
            },
          }
        });
      }
    },

    disabledMenu(full=null) {
      full ? this.page = null : false;
      document.querySelector('#app').style.pointerEvents = "none";
      document.querySelector('.cs-loader').classList.remove('d-none');
    },

    unDisabledMenu() {
      document.querySelector('#app').style.pointerEvents = "auto";
      document.querySelector('.cs-loader').classList.add('d-none');
    },

    insertToInput(e) {
      if(this.page === 'update') {
        this.article.find = e;
      } else {
        this.article.del = e;
      }
    },

    toMainPage() {
      this.$router.push({name: 'welcome'})
    },

    clearArticleOptions(p) {
      p !== 'title' ? this.article.title = null : false;
      p !== 'text' ? this.editorData = '' : false;
      p !== 'del' ? this.article.del = null : false;
      p !== 'link' ? this.article.link = null : false;
      p !== 'find' ? this.article.find = null : false;
      p !== 'type' ? this.article.type = null : false;
      p !== 'articles' ? this.article.articles = null : false;
      p !== 'tisearchtle' ? this.article.search = false : false;

      document.querySelector("input[type='file']") !== null ? document.querySelector("input[type='file']").value = '' : false;
      this.job.title = "";
    },

    removeHoverClass() {
      var items = document.querySelectorAll('ul > li > a');
      items.forEach(e => { e.classList.remove('hover'); })
    },

    auth() {
      const admin = sessionStorage.getItem('admin');

      if(!admin) {
        this.clearArticleOptions();
        return this.$router.push({ name: 'welcome' });
      } 
    },

    createPage() {
      this.clearArticleOptions();
      this.removeHoverClass();
      this.disabledMenu(1);
      document.querySelector(".createPage").classList.add('hover');
      this.page = 'create';
      this.unDisabledMenu();
    },

    async addJob() {
      try {
        this.clearArticleOptions();
        this.removeHoverClass();
        this.disabledMenu(1);

        const request = await this.jobs_show();
        const status = request.status;
        const message = request.message;
        const list = request.job_list;

        if(status === "success") {
          document.querySelector(".addJob").classList.add('hover');
          this.job_list = list;
          this.page = 'addJob';
        } else {
          toastr.error(message)
        }
      } catch (error) {
        toastr.error("Щось трапилось! Спробуйте ще раз, але пізніше!")
        console.error(error);
      }

      this.unDisabledMenu();
    },

    async debtorsFunc() {
      try {
        const request = await this.all_debtors();
        if(request['status'] === 200) {
          var debtors_filter = request['message'];
          debtors_filter.forEach(debtor => {
            if(this.debtors.length !== 0) {
              var debtor_exist = this.debtors.some(item => item['account'] === debtor['account'])
              if(debtor_exist === false) {
                this.debtors.push(debtor);
              }
              } else {
                this.debtors.push(debtor);
              }
          });

          sessionStorage.setItem('debtors', JSON.stringify(this.debtors))
        } else {
          toastr.error(request['message'])
        }
      } catch(error) {
        console.error(error)
        toastr.error('Боржники відсутні!')
      }
    },

    async debtorsPage() {
      this.clearArticleOptions();
      this.removeHoverClass();
      this.disabledMenu(1);

      var debtors = sessionStorage.getItem('debtors');
      if(debtors !== null) {
        debtors = JSON.parse(debtors);
        this.debtors = debtors
      } else {
        await this.debtorsFunc();
      }

      document.querySelector(".debtorsPage").classList.add('hover');
      this.page = 'debtors';
      this.unDisabledMenu();
    },

    importExcelPage() {
      this.clearArticleOptions();
      this.removeHoverClass();
      this.disabledMenu(1);
      document.querySelector(".importExcel").classList.add('hover');
      this.page = 'import';
      this.unDisabledMenu();
    },

    async showOrders() {
      try {
        const request = await this.showOrdersFunc();
        if(request['status'] === 200) {
          this.orders = request['message'];
        }
      } catch(error) {
        console.error(error)
        toastr.error('Обгрунтовані закупівлі відсутні!')
      }
    },

    async showAnnouncementOrders() {
      try {
        const request = await this.showAnnouncementOrdersFunc();
        if(request['status'] === 'success') {
          const files = request.files;
          const update_file_names = files.map(file => {
            const updatedFile = { ...file };
            updatedFile.filename = file.filename.split('zhylservice_orders/')[1];
            return updatedFile;
          });
          this.announcementOrders = update_file_names;
        }
      } catch(error) {
        console.error(error)
        toastr.error('Оголошені закупівлі відсутні!')
      }
    },

    async importOrderPage() {
      this.clearArticleOptions();
      this.removeHoverClass();
      this.disabledMenu(1);

      await this.showOrders();
      await this.showAnnouncementOrders();
      
      document.querySelector(".importOrder").classList.add('hover');
      this.page = 'orders';
      this.unDisabledMenu();
    },

    async mailPage() {
      this.clearArticleOptions();
      this.removeHoverClass();
      this.disabledMenu(1);

      await this.showMails();

      document.querySelector(".mailPage").classList.add('hover');
      this.page = 'mail';
      this.unDisabledMenu();
    },

    async usersPage() {
      this.clearArticleOptions();
      this.removeHoverClass();
      this.disabledMenu(1);

      await this.usersFunc();

      document.querySelector(".usersPage").classList.add('hover');
      this.page = 'users';
      this.unDisabledMenu();
    },

    async getAllArticles() {
      try {
        var request;

        var articlesStorage = localStorage.getItem("articles");

        if(articlesStorage !== null) {
          articlesStorage = JSON.parse(articlesStorage);
          const time = articlesStorage.updated_at;

          if(new Date().getTime() - time < 300000) {
            request = articlesStorage.request;
          } else {
            request = await this.getAllArticlesFunc();
          }
        } else {
          request = await this.getAllArticlesFunc();
        }


        if(request.status === 200) {
          this.article.articles = request.result;

          const articlesData = {
            request: request,
            updated_at: new Date().getTime()
          }

          localStorage.setItem("articles", JSON.stringify(articlesData));
        }
      } catch(error) {
        console.error(error);
        this.getAllArticles();
      }
    },

    async updatePage() {
      await this.clearArticleOptions();
      await this.removeHoverClass();
      await this.disabledMenu(1);
      document.querySelector(".updatePage").classList.add('hover');

      try {
        await this.getAllArticles();
      } catch(error) {
        console.error(error)
        await this.updatePage();
      }

      this.page = 'update';
      await this.unDisabledMenu();
    },

    async deletePage() {
      await this.clearArticleOptions();
      await this.removeHoverClass();
      await this.disabledMenu(1);
      document.querySelector(".deletePage").classList.add('hover');

      try {
        await this.getAllArticles();
      } catch(error) {
        await console.error(error)
        this.deletePage();
      }
      this.page = 'delete';
      await this.unDisabledMenu();
    },

    async importExcel() {
      var city = this.import_data.city;
      var files = document.getElementById("importFile");
      files = files.files;

      if (files.length !== 0) {
        this.disabledMenu();

        try {
          const request = await this.importExcelFunc(files[0], city);
          const status = request.status;
          const message = request.message;
          if (status === 200) {
            toastr.success(message);
          } else {
            toastr.error(message);
          }
          
        } catch (error) {
          toastr.error('Пошкоджений файл, або невірно сформований вміст!');
          console.error(error);
        } finally {
          this.unDisabledMenu();
        }
      } else {
        toastr.info("Виберіть файл!");
      }
    },

    async importOrder() {
      var files = document.getElementById("puchaseRationale");
      files = files.files;

      if (files.length !== 0) {
        this.disabledMenu();

        try {
          const request = await this.uploadOrderFunc(files[0]);
          const message = request.message;

          if (request.status === 200) {
            toastr.success(message);
            this.importOrderPage();
          } else {
            toastr.error(message);
          }
          
        } catch (error) {
          toastr.error('Упс... Щось трапилось! Спробуйте ще раз!');
          console.error(error);
        } finally {
          this.unDisabledMenu();
        }
      } else {
        toastr.info("Виберіть файл!");
      }
    },

    async importAnnouncementOrder() {
      var files = document.getElementById("puchaseAnnouncement");
      files = files.files;

      if (files.length !== 0) {
        this.disabledMenu();

        try {
          const request = await this.uploadAnnouncementOrderFunc(files[0]);
          toastr.info(request.response);
          if(request.status === 'success') {
            this.importOrderPage();
          }
        } catch (error) {
          toastr.error('Упс... Щось трапилось! Спробуйте ще раз!');
          console.error(error);
        } finally {
          this.unDisabledMenu();
        }
      } else {
        toastr.info("Виберіть файл!");
      }
    },

    async updateArticle() {
      this.disabledMenu();
      var title = this.article.title;
      var text = this.editorData;
      var type = this.article.type;
      var images = document.querySelector("input[type='file']").files

      var requiredFields = [];

      if(title !== null && text !== null && type !== null) {
        if(title.replaceAll(' ', '').length !== 0 && text.replaceAll(' ', '').length !== 0 ) {
          title = this.replaceSymbols(title);
          text = this.replaceSymbols(text);

          try {
            const currentyTitle = localStorage.getItem('articleTitle');
            const request = await this.updateArticleFunc(currentyTitle, title, text, type, images);
            this.clearArticlesStorage();
            if(request.canceledImages.length > 0) {
              const canceledImages = request.canceledImages.join(', ');
              if(request.canceledImages.length === 1) {
                toastr.success(`Стаття успішно оновлена, окрім ${canceledImages} через не вірне розширення зображення!`)
              } else {
                toastr.warning(`Стаття успішно оновлена, окрім ${canceledImages} через не вірне розширення зображень!`)
              }
            } else {
              toastr.success(request.result)
            }

            this.clearArticleOptions();
            this.updatePage();
          } catch(error) {
            toastr.error(error);
            console.error(error);
          }
        } else {
          title.replaceAll(' ', '').length === 0 ? requiredFields.push('назва статті') : false;
          text.replaceAll(' ', '').length === 0 ? requiredFields.push('текст статті') : false;

          requiredFields.length > 1 ? requiredFields = requiredFields.join(', ') : requiredFields = requiredFields[0];

          toastr.error(`Не заповнені обов'язкові поля: ${requiredFields}!`)
        }
      } else {
        title === null ? requiredFields.push('назва статті') : false;
        text === null ? requiredFields.push('текст статті') : false;
        type === null ? requiredFields.push('тип статті') : false;

        requiredFields.lenght > 1 ? requiredFields = requiredFields.join(', ') : requiredFields = requiredFields[0];

        toastr.error(`Не заповнені обов'язкові поля: ${requiredFields}!`)
      }

      this.unDisabledMenu();
    },

    replaceSymbols(text) {
      return text.trim();
    },

    async createArticle() {
      this.disabledMenu();

      var title = this.article.title;
      var text = this.editorData;
      var type = this.article.type;
      var images = document.querySelector("input[type='file']").files

      var requiredFields = [];
      
      if(title !== null && text !== null && images.length !== 0 && type !== null) {
        if(title.replaceAll(' ', '').length !== 0 && text.replaceAll(' ', '').length !== 0 && images.length !== 0) {
          title = this.replaceSymbols(title);
          text = this.replaceSymbols(text);

          try {
            const request = await this.createArticleFunc(title, text, type, images);
            if(request.status === 200) {
              this.clearArticlesStorage();
              if(request.canceledImages.length > 0) {
                const canceledImages = request.canceledImages.join(', ');
                if(request.canceledImages.length === 1) {
                  toastr.warning(`Стаття успішно оновлена, окрім ${canceledImages} через не вірне розширення зображення!`)
                } else {
                  toastr.warning(`Стаття успішно оновлена, окрім ${canceledImages} через не вірне розширення зображень!`)
                }
              } else {
                toastr.success(request.result)
              }
            } else {
              toastr.error(request.result)
            }
          } catch(error) {
            toastr.error("Стаття з таким ім'ям вже існує!");
            console.error(error);
          }

        } else {
          title.replaceAll(' ', '').length === 0 ? requiredFields.push('назва статті') : false;
          text.replaceAll(' ', '').length === 0 ? requiredFields.push('текст статті') : false;
          images.length === 0 ? requiredFields.push('зображення для статті') : false;

          requiredFields.length > 1 ? requiredFields = requiredFields.join(', ') : requiredFields = requiredFields[0];

          toastr.error(`Не заповнені обов'язкові поля: ${requiredFields}!`)
        }
      } else {
        title === null ? requiredFields.push('назва статті') : false;
        text === null ? requiredFields.push('текст статті') : false;
        type === null ? requiredFields.push('тип статті') : false;
        images.length === 0 ? requiredFields.push('зображення для статті') : false;

        requiredFields.lenght > 1 ? requiredFields = requiredFields.join(', ') : requiredFields = requiredFields[0];

        toastr.error(`Не заповнені обов'язкові поля: ${requiredFields}!`)
      }

      this.unDisabledMenu();
    },

    async delete_job(index) {
      this.disabledMenu();

      try {
        const id = this.job_list[index].id;
        console.log(id)
        const request = await this.delete_job_execute(id);
        const status = request.status;
        const message = request.message;
        
        if (status === "success") {
          this.addJob();
        } else {
          toastr.error(message);
        }
      } catch(error) {
        toastr.error("Щось трапилося! Спробуйте ще раз!");
        console.error(error);
      }

      this.unDisabledMenu();
    },

    update_job(index) {
      const currentJob = this.job_list[index];
      const id = currentJob.id;
      const title = currentJob.title;
      const description = currentJob.description;

      this.job.title = title;
      this.job.id = id;
      this.editorData = description;
    },

    async addNewJob() {
      this.disabledMenu();

      var title = this.job.title;
      var description = this.editorData;
      var job_id = this.job.id;

      var requiredFields = [];
      
      if(![title, description].includes(null)) {
        var title_without_spaces = title.replaceAll(" ", "").length;
        var description_without_spaces = description.replaceAll(" ", "").length;

        if(![title_without_spaces, description_without_spaces].includes(0)) {
          title = this.replaceSymbols(title);
          description = this.replaceSymbols(description);

          try {
            const request = await this.add_new_job(job_id, title, description);
            const status = request.status;
            const message = request.message;

            if(status === "success") {
              toastr.success(message);
              await this.addJob();
            } else {
              toastr.error(message)
            }
          } catch(error) {
            toastr.error("Щось трапилось, спробуйте ще раз!");
            console.error(error);
          }
        } else {
          title_without_spaces === 0 ? requiredFields.push('назва вакансії') : false;
          description_without_spaces === 0 ? requiredFields.push('опис вакансії') : false;

          requiredFields.length > 1 ? requiredFields = requiredFields.join(', ') : requiredFields = requiredFields[0];

          toastr.error(`Не заповнені обов'язкові поля: ${requiredFields}!`)
        }
      } else {
        title_without_spaces === 0 ? requiredFields.push('назва вакансії') : false;
        description_without_spaces === 0 ? requiredFields.push('опис вакансії') : false;

        requiredFields.length > 1 ? requiredFields = requiredFields.join(', ') : requiredFields = requiredFields[0];

        toastr.error(`Не заповнені обов'язкові поля: ${requiredFields}!`)
      }

      this.unDisabledMenu();
      return;
    },

    clearArticlesStorage() {
      var articlesStorage = localStorage.getItem("articles");
      if(articlesStorage !== null) {
        localStorage.removeItem("articles");
      }
    },

    async deleteArticle() {
      this.disabledMenu();
      const title = this.article.del;
      if(title === null) {
        toastr.error(`Вибачте, та назва статті - це обов'язковий параметр!`)
      } else {
        if(title.replaceAll(' ', '').length === 0) {
          toastr.error(`Вибачте, та назва статті - це обов'язковий параметр!`)
        } else {
          try {
            const request = await this.deleteArticleFunc(title);
            if(request.status === 200) {
              try {
                this.clearArticlesStorage();

                const requestGetArticles = await this.getAllArticlesFunc();
                if(requestGetArticles.status === 200) {
                  if(requestGetArticles.result.length > 0) {
                    this.article.articles = requestGetArticles.result;
                  } else {
                    this.article.articles = null;
                  }
                }
              } catch(error) {
                console.error(error)
              }

              this.article.del = null;
            }
            toastr.warning(request.result);
            this.deletePage();
          } catch(error) {
            toastr.error(error);
            console.error(error);
          }
        }
      }

      this.unDisabledMenu();
    },

    async deleteAllArticles() {
      const p = prompt('Ви впевнені, що хочете видалити весь матеріал?', 'Так/Ні');
      if(p && p.toLowerCase() === 'так') {
        this.disabledMenu();
        try {
          const request = await this.deleteAllArticlesFunc();
          if(request.status === 200) {
            this.clearArticlesStorage();

            this.article.articles = null;
            this.article.del = null;
            this.deletePage();
          }
          toastr.warning(request.result);
        } catch(error) {
          toastr.error(error);
          console.error(error);
        }

        this.unDisabledMenu();
      } else {
        toastr.info('Відміна видалення ...')
      }
    },

    async findArticle(currentyTitle=null) {
      const title = currentyTitle !== null ? currentyTitle : this.article.find;
      this.article.find = currentyTitle !== null ? currentyTitle : this.article.find;
      this.disabledMenu();

      if(title === null) {
        toastr.error(`Вибачте, та назва статті - це обов'язковий параметр!`)
      } else {
        if(title.replaceAll(' ', '').length === 0) {
          toastr.error(`Вибачте, та назва статті - це обов'язковий параметр!`)
        } else {
          try {
            const request = await this.getArticleFunc(title);
            if(request.status === 200) {
              this.article.search = true;
              this.article.title = request.result.title;
              this.editorData = request.result.text;
              this.article.type = request.result.articleType;

              localStorage.setItem('articleTitle', request.result.title);
              setTimeout(() => {
                var selectElement = document.getElementById('articleType');
                if(this.article.type !== null) {
                  if(this.article.type == 1) {
                    selectElement.options[0].selected = true;
                  } else {
                    selectElement.options[1].selected = true;
                  }
                }
                
                window.scrollTo(0, 10000)
                this.imagesLoading(request.result.images, request.result.title);
              }, 1);
            } else {
              toastr.error(request.result);
            }
          } catch(error) {
            toastr.error(error);
            console.error(error);
          }
        }
      }

      this.unDisabledMenu();
    },

    imagesLoading(images, article) {
      document.querySelector("input[type='file']").value = '';
      const imagesSet = document.querySelector('.imagesSet');
      imagesSet.innerHTML = '';
      const i = images;
      i.forEach(e => {
        if(e.length > 0) {
          const link = `${this.url}/articles/image/${e}`;

          const div = document.createElement('div');
          const a = document.createElement('a');
          const img = document.createElement('img');
          const span = document.createElement('span');

          img.style.width = '100px';
          img.style.height = '100px';
          img.classList.add('m-2');
          img.src = link;

          a.href = link;
          a.target = '_blank';
          a.append(img);

          span.innerText = 'x';
          span.style.cursor = 'pointer';
          span.setAttribute('filename', e);
          span.setAttribute('article', article);

          div.classList.add('d-flex');
          div.append(a);
          div.append(span);

          imagesSet.prepend(div);

          span.addEventListener('click', async (e) => {
            this.disabledMenu();
            const currentyTitle = span.getAttribute('article');
            const filename = span.getAttribute('filename');

            const response = await this.deleteArticleImage(currentyTitle, filename); 
            if(response.status === 200) {
              div.remove(e);
            } else {
              toastr.error(response.result)
            }

            this.unDisabledMenu();
          })
        }
      })
    },
  }
}
</script>

<style>
  body {
    background: #F7F7F7;
  }

  a {
    cursor: pointer;
  }

  .hover {
    font-weight: bold;
    background: white;
    color: black;
  }

  table.dataTable {
    border-collaps: collaps;
  }

  .paging_simple_numbers {
    margin-top: 15px;
  }

  .ck-editor__main .ck-editor__editable {
    height: 300px;
  }
</style>