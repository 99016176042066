import { createRouter, createWebHistory } from 'vue-router';
import WelcomePage from './components/WelcomePage.vue';
import ProfilePage from './components/ProfilePage.vue';
import AdminPage from './components/AdminPage.vue';
import SessionView from './components/SessionView.vue';
import TariffsView from './components/TariffsView.vue';


const router = createRouter({
	mode: 'history',
	history: createWebHistory(),
	routes: [
		{path: '/', component: WelcomePage, name: 'welcome'},
		{path: '/user/profile', component: ProfilePage, name: 'profile'},
		{path: '/admin', component: AdminPage, name: 'admin'},
		{path: '/session/', component: SessionView, name: 'session', props: (route) => ({ data: route.query.data })},
		{path: '/tariffs', component: TariffsView, name: 'tariffs'}
	]
});


export default router;