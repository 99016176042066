<template>
  <div class="dropdown position-fixed bottom-0 end-0 mb-3 me-3 up-block">
    <button class="btn btn-bd-primary py-2 d-flex align-items-center text-white shadow up-button" @click="up">
      <svg width="20px" height="20px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <polygon points="50,20 80,80 20,80" style="fill:white;stroke:white;stroke-width:3;"/>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'UpButtonComponent',
  methods: {
    up() {
      return window.scrollTo(0, 0)
    }
  }
}
</script>
