<template>
  <div class="dropdown position-fixed end-0 mt-3 me-3 up-block">
    <button class="btn dropdown-toggle py-2 d-flex align-items-center bg-light text-dark shadow" type="button" aria-expanded="false" data-bs-toggle="dropdown" aria-label="Toggle theme (light)">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="20" height="20">
        <!-- Солнце -->
        <circle cx="50" cy="50" r="40" fill="orange" />

        <!-- Лучи солнца -->
        <line x1="50" y1="10" x2="50" y2="0" stroke="orange" stroke-width="10" />
        <line x1="50" y1="90" x2="50" y2="100" stroke="orange" stroke-width="10" />
        <line x1="10" y1="50" x2="0" y2="50" stroke="orange" stroke-width="10" />
        <line x1="90" y1="50" x2="100" y2="50" stroke="orange" stroke-width="10" />

        <!-- Диагональные лучи -->
        <line x1="30" y1="30" x2="20" y2="20" stroke="orange" stroke-width="10" />
        <line x1="70" y1="70" x2="80" y2="80" stroke="orange" stroke-width="10" />
        <line x1="70" y1="30" x2="80" y2="20" stroke="orange" stroke-width="10" />
        <line x1="30" y1="70" x2="20" y2="80" stroke="orange" stroke-width="10" />
      </svg>
    </button>
    <ul class="dropdown-menu dropdown-menu-end shadow px-2" aria-labelledby="bd-theme-text">
      <li>
        <label for="fontSize" class="form-label">Розмір шрифту:</label>
        <input type="range" class="form-range" id="fontSize" min="1" max="3" step="1" v-model="fontSize" @change="fontSizeChanger">
      </li>
      <li>
        <label class="form-label">Задній фон:</label>
        <button type="button" class="dropdown-item d-flex align-items-center justify-content-center" data-bs-theme-value="success" aria-pressed="true" @click="switcher(1)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="20" height="20">
            <circle cx="50" cy="50" r="40" fill="green" />
          </svg>
        </button>
      </li>
      <li>
        <button type="button" class="dropdown-item d-flex align-items-center justify-content-center" data-bs-theme-value="secondary" aria-pressed="false" @click="switcher(2)">    
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="20" height="20">
            <circle cx="50" cy="50" r="40" fill="grey" />
          </svg>
        </button>
      </li>
      <li>
        <button type="button" class="dropdown-item d-flex align-items-center justify-content-center" data-bs-theme-value="dark" aria-pressed="false" @click="switcher(3)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="20" height="20">
            <circle cx="50" cy="50" r="40" fill="black" />
          </svg>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'UpButtonComponent',
  data() {
    return {
      fontSize: "2"
    }
  },
  mounted() {
    this.fontSizeInstaller();
  },
  methods: {
    fontSizeChanger() {
      var size = this.fontSize;
      localStorage.setItem("fontSize", size);
      if(size === "1") {
        size = "12px"
      } else if(size === "3") {
        size = "18px"
      } else if(size === "2") {
        size = "1rem";
      }

      document.body.style.fontSize = size
    },

    fontSizeInstaller() {
      var size = localStorage.getItem("fontSize");
      if(size !== null && size !== "2") {
        this.fontSize = size
        if(size === "1") {
          size = "12px"
        } else if(size === "3") {
          size = "20px"
        } else if(size === "2") {
        size = "1rem";
      }

        document.body.style.fontSize = size
      }
    },

    switcher(color) {
      localStorage.setItem('theme', color);

      const titleBlock = document.querySelector('.title-block');
      const upBlock = document.querySelector('.up-button');
      const burger = document.querySelector('.burger-btn');
      const authModalButton = document.querySelector('#authModalButton');

      const needEdit = [titleBlock, upBlock, authModalButton, burger];

      var bg = 'bg-success';

      if(color == 2) {
        bg = 'bg-secondary';
      } else if(color == 3) {
        bg = 'bg-dark';
      } 

      needEdit.forEach(e => {
        if(e !== null) {
          e.classList.remove('bg-success', 'bg-secondary', 'bg-dark');
          e.classList.add(bg);
        }
      })
    }
  }
}
</script>
