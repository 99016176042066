<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Калькулятор вирахування платежу за 1м2</h6>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="calculator.modal = false"></button>
      </div>
      <div class="modal-body">
        <!-- <form>
          <label for="tariff" class="form-label">Таріф (грн.)</label>
          <input type="number" min="0" class="form-control" id="tariff" v-model="tariff">

          <label for="meters" class="form-label">Метраж (м2)</label>
          <input type="number" min="0" class="form-control" id="meters" v-model="meters">

          <div class="mt-4 w-100 d-flex align-items-center justify-content-center">
            <label for="equals" class="mb-0 btn btn-dark form-label fw-bold equals" @click="equals">=</label>
          </div>

          <div class="mt-3 w-100 d-flex align-items-center justify-content-center" v-if="result !== null">
            <h3>{{result}} грн. за м2</h3>
          </div>
        </form> --> 
        <p class="m-0">Сервіс у розробці ...</p>
      </div>
      <div class="modal-footer">
        <!-- <a href="/tariffs" target="_blank" class="btn btn-primary">Відобразити тарифи</a> -->
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="calculator.modal = false">Закрити</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalculatorModal",
  props: {
    calculator: Object,
  },

  data() {
    return {
      tariff: 0,
      meters: 0,
      result: 0
    }
  },

  methods: {
    equals() {
      var result = this.tariff * this.meters;
      this.result = result;
      return;
    },
  }
}
</script>