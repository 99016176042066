<template>
  <div class="d-flex align-items-center justify-content-center">
    <img :src="image" />
  </div>
</template>

<script>
import Image from '@/assets/logo.jpg';

export default {
    name: "TariffsView",
    data() {
      return {
        image: Image
      }
    }
}
</script>