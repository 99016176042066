<template>
  <div class="modal d-block" tabindex="-1" >
    <div class="modal-dialog d-flex align-items-center justify-content-center modal-dialog-scrollable">
      <div class="modal-content debtAlert-modal text-dark">
        <div class="modal-header">
          <h4 class="modal-title text-dark">{{ modal.job.title }}</h4>
          <button type="button" class="btn-close btn-close-dark" data-bs-dismiss="modal" aria-label="Close" @click="close_modal"></button>
        </div>
        <div class="modal-body job-description">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JobModal",
  props: {
    modal: Object,
  },
  mounted() {
    document.querySelector(".job-description").innerHTML = this.modal.job.description
  },
  methods: {
    close_modal() {
      return this.$emit('close_modal');
    }
  },
};
</script>