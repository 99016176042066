<template>
  <div class="modal d-block" tabindex="-1" >
    <div class="modal-dialog d-flex align-items-center justify-content-center modal-dialog-scrollable">
      <div class="modal-content debtAlert-modal text-dark">
        <div class="modal-header">
          <h4 class="modal-title text-danger">УВАГА!</h4>
          <button type="button" class="btn-close btn-close-dark" data-bs-dismiss="modal" aria-label="Close" @click="debtAlert.modal = false"></button>
        </div>
        <div class="modal-body">
          <p class="m-0 fw-bold">Вітаємо! Хочемо нагадати щодо Вашого боргу у загальній сумі {{ debt }} грн. {{ accounts }}. Якщо Ви вже виконали оплату, то не переймайтесь, після наступного оновлення, це повідомлення більше не буде Вас турбувати!</p>
          <p class="mt-3">З повагою, команда КП Жилсервіс!</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DebtModal",
  props: {
    debtAlert: Object,
  },
  data() {
    return {
      debt: 0,
      accounts: "",
    };
  },
  mounted() {
    this.debtModalProcess();
  },
  methods: {
    async debtModalProcess() {
      try {
        var accounts = [];
        var debt_amount = 0;
        await Promise.all(
          this.debtAlert.debts.map(async (debt) => {
            console.log(debt.debt);
            debt_amount += debt.debt;
            accounts.push(debt.account);
          })
        );

        this.debt = parseFloat(debt_amount).toFixed(2);
        this.accounts =
          accounts.length > 1
            ? "по рахункам " + accounts.join(", ")
            : "по рахунку " + accounts[0];
      } catch (error) {
        console.error("Error processing debts", error);
      }
    },
  },
};
</script>