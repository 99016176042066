import { createStore } from 'vuex';

const store = createStore({
	state: {
		url: 'https://zhylservice-prod-dfcc31fdb708.herokuapp.com',
		// url: 'http://127.0.0.1:8000',
		awsSnsApiKey: 'QofUz0TfWU7cEjg2tDAMW5eRM6tGKujj5locX6vW',
		awsSnsApiUrl: 'https://ztyyggkrf7.execute-api.us-east-1.amazonaws.com/SendMessageToPhone',
		confirmedWindow: false,
		secretKey: "zhilservice-application", 
	},
	getters: {
		getUrl(state) {
			return state.url;
		},

		getAwsSnsApiKey(state) {
			return state.awsSnsApiKey;
		},

		getAwsSnsApiUrl(state) {
			return state.awsSnsApiUrl;
		},

		getConfirmedWindowState(state) {
			return state.confirmedWindow;
		},

		getSecretKey(state) {
			return state.secretKey;
		}
	},
	mutations: {
		editConfirmedWindowState(state) {
		    state.confirmedWindow = !state.confirmedWindow;
		}
	}
})

export default store;