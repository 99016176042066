<template>
  <div class="modal d-block" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">Повідомлення від {{currentyMessage.name}}</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="mail.modal = false"></button>
        </div>
        <div class="modal-body">
          <p class="m-0">{{currentyMessage.message}}</p>
        </div>
        <hr class="m-0" />
        <div class="modal-body">
          <div class="mb-3">
            <label for="city">Відповісти <span class='text-danger'>*</span></label>
            <div class="input-group">
              <textarea class="form-control w-100" style="resize: none;" rows="5" v-model="mail.answer"></textarea>
            </div>
          </div>
          <button class="btn btn-warning btn-lg btn-block" @click="answerMail(currentyMessage.email)" type="submit">Відповісти</button>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="mail.modal = false">Закрити</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toastr from 'toastr';
import 'toastr/toastr.scss';
import answerMailFunc from '../../mixins/api.js';

export default {
    name: "MailsModal",
    mixins: [answerMailFunc],
    props: {
      mail: Object,
      currentyMessage: Object,
      unDisabledMenu: Function,
      disabledMenu: Function
    },
    mounted() {
      toastr.options = {
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-top-center',
        timeOut: 1300,
        toastClass: 'custom-toast',
      }
    },
    methods: {
      async answerMail(email) {
        const msg = this.mail.answer;

        if(msg.replaceAll(' ', '').length !== 0) {
          this.disabledMenu();
          try {
            const request = await this.answerMailFunc(email, msg);
            if(request.status === 200) {
              this.unDisabledMenu();
              return toastr.success(request.message)
            } 

            this.unDisabledMenu();
            return toastr.error(request.message)
          } catch(error) {
            console.error(error)
            toastr.error(error)
          }
        } 

        this.unDisabledMenu();
        return toastr.info('Ви не можете відправити пусте повідомлення!')
      },
    }
}
</script>