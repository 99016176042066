<template>
  <div v-if="debtors.modal" class="modal d-block bg-dark bg-opacity-75" tabindex="-1">
    <div class="modal-dialog d-flex align-items-center modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">Список нарахувань оплати за комунальні послуги та опалення станом на {{ currentyDay() }}</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="debtors.modal = false"></button>
        </div>
        <div class="modal-body">
          <div class="table-responsive mb-0" style="max-height: 300px;">
            <table class="table-bordered border-primary w-100 text-center">
              <thead>
                <tr>
                  <th scope="col">Ім'я</th>
                  <th scope="col">Рахунок</th>
                  <th scope="col">Борг</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for='debtor in users' :key='debtor.id'>
                  <td class='mt-1' :title="debtor.f">{{debtor.f}}</td>
                  <td class='mt-1' :title="debtor.account">{{debtor.account}}</td>
                  <td class='mt-1' :title="debtor.all_sum">{{debtor.all_sum}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "DebtorsModal",
    props: {
      users: Array
    },
    data() {
      return {
        debtors: {
          modal: true,
        },
      }
    },
    methods: {
      currentyDay() {
        var day = new Date().getDate();
        var month = new Date().getMonth() + 1;
        var year = new Date().getFullYear();

        day < 10 ? day = `0${day}` : false;
        month < 10 ? month = `0${month}` : false;

        return `${day}.${month}.${year}`;
      }
    }
}
</script>