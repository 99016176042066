<template>
  <div class="contucts-form">
    <div class="card contuctPageCard m-3">
      <div class="card-header bg-ligth text-black"><i class="fa fa-envelope"></i> Скринька довіри
      </div>
      <div class="card-body">
          <form>
              <div class="form-group">
                  <label for="name">Ім'я</label>
                  <input type="text" class="form-control" id="name" aria-describedby="nameHelp" placeholder="Введіть ваше ім'я (Іван Іванов)" required>
              </div>
              <div class="form-group">
                  <label for="email">Пошта</label>
                  <input type="email" class="form-control" id="email" aria-describedby="emailHelp" placeholder="Введіть вашу пошту (ivanov@gmail.com)" required>
              </div>
              <div class="form-group">
                  <label for="email">Номер телефона</label>
                  <input type="tel" class="form-control" id="phone" aria-describedby="phoneHelp" value='380' placeholder="Введіть ваш номер телефона (380...)" required>
              </div>
              <div class="form-group">
                  <label for="message">Текст</label>
                  <textarea class="form-control" id="message" rows="6" required style='resize:none;'></textarea>
              </div>
              <div class="mx-auto mt-2">
              <button type="submit" class="btn btn-warning text-right sendMail">Відправити</button></div>
          </form>
      </div>
    </div>

    <div class="card bg-light mb-3 contuctPageCard m-3">
      <div class="card-header bg-ligth text-black"><i class="fa fa-home"></i>Реквізити для оплати послуг</div>
      <div class="card-body">
          <p>Оплата за комунальні послуги проводиться за реквізитами:</p>
          <ul class="fw-bold">
            <li>ЄДРПОУ/ДРФО: 45131360</li>
            <li>МФО: 305299</li>
            <li>РОЗРАХУНКОВИЙ РАХУНОК: UA143052990000026009050533833</li>
          </ul>

          <p>Оплата за опалення проводиться за реквізитами:</p>
          <ul class="fw-bold">
            <li>ЄДРПОУ/ДРФО: 45131360</li>
            <li>МФО: 305299</li>
            <li>РОЗРАХУНКОВИЙ РАХУНОК: UA523052990000026001050576489</li>
          </ul>
      </div>
    </div>

    <div class="card bg-light mb-3 contuctPageCard m-3">
      <div class="card-header bg-ligth text-black"><i class="fa fa-home"></i>Наші контакти</div>
      <div class="card-body">
          <p>Адреса: <strong>Дніпропетровська область, Новомосковський район, смт. Черкаське, вул. Лісна, буд. 18</strong></p>
          <p>Пошта: <a class="fw-bold" href='mailto:zhylservice@gmail.com'>zhylservice@gmail.com</a></p>
          <p>Телефони: </p>
          <ul class="fw-bold">
            <li>067-29-65-937 - ДИСПЕТЧЕР ЦІЛОДОБОВО</li>
            <li>098-30-97-701 - інженер з організації експлуатації та ремонту Назаренко Олена Вікторівна (смт.Гвардійське)</li>
            <li>098-34-90-675 - бухгалтер по роботі з населенням Бігун Тамара Юхимівна (смт. Гвардійське)</li>
            <li>068-58-11-644 - інженер з організації експлуатації та ремонту Бахілов Олександр Павлович (смт. Черкаське)</li>
            <li>068-56-52-032 - бухгалтер по роботі з населенням Степаненко Тамара Вікторівна (смт.Черкаське)</li>
          </ul>
          <p>Розклад звернень:</p> 
          <ul class="fw-bold m-0">
            <li>Понеділок-четвер з 8:00 год. до 17:15 год.</li>
            <li>П'ятниця з 8:00 год. до 16:00 год.</li>
          </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "ContactComponent",
    
}
</script>