<template>
  <div class="modal d-block" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">{{users.currentUser.f}}</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="users.modal = false"></button>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table class="table-bordered border-primary text-center">
              <thead>
                <tr>
                  <th  class="p-2" scope="col">#</th>
                  <th  class="p-2" scope="col">Номер о/р</th>
                  <th  class="p-2" scope="col">Місто</th>
                  <th  class="p-2" scope="col">Адреса</th>
                  <th  class="p-2" scope="col">Послуга</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for='account in users.currentAccounts' :key='account.id'>
                  <td class="p-2">{{account.id}}</td>
                  <td class="p-2">{{account.account}}</td>
                  <td class="p-2">{{account.city}}</td>
                  <td class="p-2">{{account.address}}</td>
                  <td class="p-2">{{account.service}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-3">
            <a v-for="accountId in users.accountsId" :key="accountId.id" type="button" class="w-100 btn btn-sm btn-outline-danger shadow shadow-danger my-1" target='_blank' @click="download(accountId.account, accountId.city)">
                <img :src="xlsx" width='30' height='30' alt='xlsx.png' />
                <span class="mx-2">Завантажити борги по рахунку <span class="text-decoration-underline">{{accountId.account}}</span></span>
            </a>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="users.modal = false">Закрити</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toastr from 'toastr';
import 'toastr/toastr.scss';
import xlsx from '@/assets/xlsx.png'

export default {
    name: "UsersModal",
    mixins: [],
    props: {
      users: Object
    },
    data() {
      return {
        url: this.$store.getters.getUrl,
        xlsx: xlsx
      }
    },
    methods: {
      download(accountId, city) {
        window.open(`${this.url}/billings/save?account=${accountId}&city=${city}`)
      }
    }
}
</script>