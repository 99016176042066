<template>
  <div class="jobs-table border rounded-3 shadow p-2 mb-2">
    <p class="mb-2 fs-6 fst-italic"><span class="text-danger fw-bold">!!!</span> Щодо будь-яких питань, звертайтеся за номером <span class="fw-bold">(067)296-59-56</span></p>
    <table class="table table-bordered text-center mb-0">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Назва вакансії</th>
          <th scope="col">Дата розміщення</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item,index in jobs_list" :key="index">
          <th scope="row">{{ index + 1 }}</th>
          <td>{{ item.title }}</td>
          <td>{{ item.date }}</td>
          <td>
            <a class="" href="" @click.prevent="open_job(index)">Опис</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div v-if="modal.state">
    <KeepAlive>
      <component :is="jobModal" :modal="modal" @close_modal="close_modal" />
    </KeepAlive>
  </div>
</template>

<script>
import JobModal from "@/components/Modals/JobModal.vue";

export default {
  name: "JobsComponent",
  components: {JobModal},
  props: {
    jobs_list: Array
  },
  data() {
    return {
      modal: {
        state: false,
        job: null
      },

      jobModal: null
    }
  },
  methods: {
    open_job(index) {
      this.modal.job = this.jobs_list[index];
      this.jobModal = "JobModal";
      this.modal.state = true;
    },
    close_modal() {
      this.modal.job = null;
      this.jobModal = null;
      this.modal.state = false;
    }
  }
}
</script>